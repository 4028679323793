[<template>
  <div id="overview">
    <v-container fluid>
      <v-layout align-center justify-center text-xs-center>
        <div text-xs-center style="width:100%">
          <v-alert v-model="userpageEditFailedAlert" type="error" transition="fade-transition" dismissible>Bijwerken van
            userpage is mislukt. Controleer de ingevulde waarden.</v-alert>
          <v-alert v-model="userpageInsertedAlert" type="success" transition="fade-transition" dismissible>Userpage is
            succesvol gegenereerd.</v-alert>
          <v-alert v-model="userpageEditedAlert" type="success" transition="fade-transition" dismissible>Userpage is
            succesvol bijgewerkt.</v-alert>
          <v-alert v-model="sliderIncorrectAlert" type="warning" transition="fade-transition" dismissible>Let op:
            Sliders zijn mogelijk niet correct ingesteld.</v-alert>
          <v-alert v-model="userpagesInsertFailedAlert" type="error" transition="fade-transition" dismissible>Genereren
            van userpages mislukt. Controleer de ingevulde waarden.</v-alert>
          <v-alert v-model="userpagesInsertedAlert" type="success" transition="fade-transition" dismissible>Userpages
            zijn succesvol gegenereerd.</v-alert>
          <v-alert v-model="genericAlert" type="error" transition="fade-transition" dismissible>Fout: {{
            genericAlertMessage }}</v-alert>
          <v-alert v-model="genericSucces" type="success" transition="fade-transition" dismissible>{{
            genericSuccesMessage }}</v-alert>
          <v-snackbar v-model="copySnackbar" :timeout="2000"> Gekopieerd </v-snackbar>

          <v-layout style="position:fixed; z-index:5; right:0; padding-top:9px; right:10px;" align-end justify-end>
            <v-tooltip bottom>
              <span>Userpage toevoegen</span>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" color="primary" @click="insertUserpage()" dark class="mb-2"
                  justify-right style="margin-right:10px;">
                  <font-awesome-icon icon="plus" size="lg" />
                </v-btn>
              </template>
            </v-tooltip>
            <v-tooltip bottom>
              <span>Meerdere userpages toevoegen</span>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" color="primary" @click="insertUserpages()" dark class="mb-2"
                  justify-right style="margin-right:10px;">
                  <font-awesome-icon icon="folder-plus" size="lg" />
                </v-btn> <!-- folder-plus-->
              </template>
            </v-tooltip>
            <v-tooltip bottom>
              <span>CSV exporteren</span>
              <template v-slot:activator="{ on, attrs }">
                <v-btn :disabled="selectedUserpages.length == 0" v-bind="attrs" v-on="on" color="primary"
                  @click="printUserpages()" dark class="mb-2" justify-right style="margin-right:10px;">
                  <font-awesome-icon icon="file-csv" size="lg" />
                </v-btn> <!-- folder-plus-->
              </template>
            </v-tooltip>
            <v-tooltip bottom>
              <span>Bulk wijzigen</span>
              <template v-slot:activator="{ on, attrs }">
                <v-btn :disabled="selectedUserpages.length == 0" v-bind="attrs" v-on="on" color="primary"
                  @click="editUserpages()" dark class="mb-2" justify-right style="margin-right:10px;">
                  <font-awesome-icon icon="edit" size="lg" />
                </v-btn> <!-- folder-plus-->
              </template>
            </v-tooltip>
            <v-tooltip bottom>
              <span>Bulk verwijderen</span>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-if="$session.get('role') == 1" :disabled="selectedUserpages.length == 0" v-bind="attrs"
                  v-on="on" color="primary" @click="deleteUserpages()" dark class="mb-2" justify-right
                  style="margin-right:10px;">
                  <font-awesome-icon icon="trash" size="lg" />
                </v-btn> <!-- folder-plus-->
              </template>
            </v-tooltip>
            <v-tooltip bottom>
              <span>Print stickers</span>
              <template v-slot:activator="{ on, attrs }">
                <v-btn :disabled="selectedUserpages.length == 0" v-bind="attrs" v-on="on" color="primary"
                  @click="printDialog = true" dark class="mb-2" justify-right style="margin-right:10px;">
                  <font-awesome-icon icon="print" size="lg" />
                </v-btn> <!-- folder-plus-->
              </template>
            </v-tooltip>
            <v-tooltip bottom>
              <span>Bulk bin download</span>
              <template v-slot:activator="{ on, attrs }">
                <v-btn :disabled="selectedUserpages.length == 0" v-bind="attrs" v-on="on" color="primary"
                  @click="downloadUserpages()" dark class="mb-2" justify-right style="margin-right:10px;">
                  <v-icon medium>mdi-cloud-download</v-icon>
                </v-btn> <!-- folder-plus-->
              </template>
            </v-tooltip>
            <v-tooltip bottom>
              <span>Toevoegen in chirpstack</span>
              <template v-slot:activator="{ on, attrs }">
                <v-btn :disabled="selectedUserpages.length == 0" v-bind="attrs" v-on="on" color="primary"
                  @click="addDevicesChirpstack()" dark class="mb-2" justify-right style="margin-right:10px;">
                  <img style="-webkit-filter: grayscale(1) invert(1); filter: grayscale(1) invert(1);"
                    src="https://chirpstack.teneo-iot.nl/icon.png" width="25" height="25">
                </v-btn> <!-- folder-plus-->
              </template>
            </v-tooltip>
          </v-layout>



          <v-card>
            <v-card-title style="padding-top:32px">Userpages
              <v-tooltip v-model="show" bottom>
                <span>Refresh userpages</span>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" @click="refreshUserpages()" class="ma-2" justify-right
                    style="margin-left:10px;" icon>
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </template>
              </v-tooltip>
              <v-spacer></v-spacer>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Zoeken" @change="selectedUserpages = []"
                single-line hide-details></v-text-field>
            </v-card-title>
            <v-data-table v-model="selectedUserpages" ref="table" :headers="headers" :items="userpages" :search="search"
              :custom-filter="customFilter" :footer-props="{
                'items-per-page-options': [10, 20, 50, 100, -1]
              }" :loading="loadUserpages" sort-by="id" :single-expand="singleExpand" :expanded.sync="expanded"
              show-select show-expand multi-sort class="elevation-1">

              <template v-slot:[`item.action`]="userpage">
                <v-icon small class="mr-2" @click="editUserpage(userpage)">edit</v-icon>
                <v-icon v-if="$session.get('role') == 1" class="mr-2" small
                  @click="deleteUserpage(userpage)">delete</v-icon>
                <v-icon class="mr-2" small @click="downloadUserpage(userpage)">mdi-cloud-download</v-icon>

              </template>

              <template v-slot:[`item.status_name`]="{ item }">
                <v-chip v-on="on" :color="item.status_color">
                  {{ item.status_name }}
                </v-chip>
              </template>

              <!-- Overview userpages -->
              <template v-slot:expanded-item="{ headers, item }">

                <td
                  v-if="item && item.config && item.config.Fuses && item.config.CommunicationService && item.config.CommunicationService.Parameters && services"
                  :colspan="headers.length">
                  <v-expansion-panels accordion>
                    <v-expansion-panel>
                      <v-row style="padding:20px 16px 0px 0px;">
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field v-model="item.config.Name" outlined label="Name" :rules="stringRules" disabled>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-select v-model="item.config.CommunicationService.Type" outlined
                            :items="['BasicCommunicationService', 'IntervalCommunicationService', 'ContinuousCommunicationService']"
                            item-text="name" label="Service Manager" disabled></v-select>
                        </v-col>
                      </v-row>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header style="padding:0px 0px;">
                        <v-select v-model="item.config.CommunicationService.Name" :rules="selectRules"
                          :items="servicesByType(item.config.CommunicationService.Type)" item-text="name" outlined
                          label="Communication Service" style="margin-left: 10px; padding-top: 30px" disabled>
                        </v-select>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content style="padding-top: 20px">
                        <v-row v-for="(parameter, parIndex) in item.config.CommunicationService.Parameters"
                          :key="parIndex">
                          <v-col cols="12" sm="6" md="4" class="paramcol" v-if="parameter.Configurable == true">
                            <v-text-field v-if="parameter.Type == 'Array'" v-mask="'NN '.repeat(parameter.ArrayLength)"
                              v-model="parameter.Value" class="paramtextfield" outlined :label="parameter.Name" dense
                              disabled></v-text-field>
                            <v-select
                              v-else-if="parameter.Type === 'BitField' && parameter.Name === 'DefaultTransmissionDatarate'"
                              v-model="parameter.ValueDecoded" :items="sfItems" outlined :label="parameter.Name"
                              class="paramtextfield" dense disabled></v-select>
                            <v-text-field
                              v-else-if="parameter.Type === 'BitField' && parameter.Name != 'DefaultTransmissionDatarate'"
                              class="paramtextfield" v-model.number="parameter.ValueDecoded" outlined
                              :label="parameter.Name" @wheel="$event.target.blur()" type="number" dense disabled>
                            </v-text-field>
                            <v-checkbox v-else-if="parameter.Type === 'Bool'" v-model="parameter.Value"
                              class="paramtextfield" outlined :label="parameter.Name" dense disabled></v-checkbox>
                            <v-text-field v-else class="paramtextfield" v-model.number="parameter.Value"
                              @wheel="$event.target.blur()" type="number" outlined :label="parameter.Name" dense
                              disabled></v-text-field>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header style="padding:0px 0px;">
                        <v-select v-model="item.config.DataService.Name" :rules="selectRules"
                          :items="servicesByType(item.config.DataService.Type)" item-text="name" outlined
                          label="Data Service" style="margin-left: 10px; padding-top: 30px" disabled></v-select>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content style="padding-top: 20px">
                        <v-row v-for="(parameter, parIndex) in item.config.DataService.Parameters" :key="parIndex">
                          <v-col cols="12" sm="6" md="4" class="paramcol" v-if="parameter.Configurable == true">
                            <v-text-field v-if="parameter.Type == 'Array'" v-mask="'NN '.repeat(parameter.ArrayLength)"
                              v-model="parameter.Value" class="paramtextfield" outlined :label="parameter.Name" dense
                              disabled></v-text-field>
                            <v-select
                              v-else-if="parameter.Type === 'BitField' && parameter.Name === 'DefaultTransmissionDatarate'"
                              v-model="parameter.ValueDecoded" :items="sfItems" outlined :label="parameter.Name"
                              class="paramtextfield" dense disabled></v-select>
                            <v-text-field
                              v-else-if="parameter.Type === 'BitField' && parameter.Name != 'DefaultTransmissionDatarate'"
                              class="paramtextfield" v-model.number="parameter.ValueDecoded" outlined
                              :label="parameter.Name" @wheel="$event.target.blur()" type="number" dense disabled>
                            </v-text-field>
                            <v-checkbox v-else-if="parameter.Type === 'Bool'" v-model="parameter.Value"
                              class="paramtextfield" outlined :label="parameter.Name" dense disabled></v-checkbox>
                            <v-text-field v-else class="paramtextfield" v-model.number="parameter.Value"
                              @wheel="$event.target.blur()" type="number" outlined :label="parameter.Name" dense
                              disabled></v-text-field>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header style="padding:0px 0px;">
                        <v-select v-model="item.config.SleepService.Name" :rules="selectRules"
                          :items="servicesByType(item.config.SleepService.Type)" item-text="name" outlined
                          label="Sleep Service" style="margin-left: 10px; padding-top: 30px" disabled></v-select>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content style="padding-top: 20px">
                        <v-row v-for="(parameter, parIndex) in item.config.SleepService.Parameters" :key="parIndex">
                          <v-col cols="12" sm="6" md="4" class="paramcol" v-if="parameter.Configurable == true">
                            <v-text-field v-if="parameter.Type == 'Array'" v-mask="'NN '.repeat(parameter.ArrayLength)"
                              v-model="parameter.Value" class="paramtextfield" outlined :label="parameter.Name" dense
                              disabled></v-text-field>
                            <v-select
                              v-else-if="parameter.Type === 'BitField' && parameter.Name === 'DefaultTransmissionDatarate'"
                              v-model="parameter.ValueDecoded" :items="sfItems" outlined :label="parameter.Name"
                              class="paramtextfield" dense disabled></v-select>
                            <v-text-field
                              v-else-if="parameter.Type === 'BitField' && parameter.Name != 'DefaultTransmissionDatarate'"
                              class="paramtextfield" v-model.number="parameter.ValueDecoded" outlined
                              :label="parameter.Name" @wheel="$event.target.blur()" type="number" dense disabled>
                            </v-text-field>
                            <v-checkbox v-else-if="parameter.Type === 'Bool'" v-model="parameter.Value"
                              class="paramtextfield" outlined :label="parameter.Name" dense disabled></v-checkbox>
                            <v-text-field v-else class="paramtextfield" v-model.number="parameter.Value"
                              @wheel="$event.target.blur()" type="number" outlined :label="parameter.Name" dense
                              disabled></v-text-field>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header style="padding:0px 0px;">
                        <v-select v-on:input="changeServiceSelection('timersingle')"
                          v-model="item.config.TimerService.Name" :rules="selectRules"
                          :items="servicesByType(item.config.TimerService.Type)" item-text="name" outlined
                          label="Timer Service" style="margin-left: 10px; padding-top: 30px" disabled></v-select>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content style="padding-top: 20px">
                        <v-row v-for="(parameter, parIndex) in item.config.TimerService.Parameters" :key="parIndex">
                          <v-col cols="12" sm="6" md="4" class="paramcol" v-if="parameter.Configurable == true">
                            <v-text-field v-if="parameter.Type == 'Array'" v-mask="'NN '.repeat(parameter.ArrayLength)"
                              v-model="parameter.Value" class="paramtextfield" outlined :label="parameter.Name" dense
                              disabled></v-text-field>
                            <v-select
                              v-else-if="parameter.Type === 'BitField' && parameter.Name === 'DefaultTransmissionDatarate'"
                              v-model="parameter.ValueDecoded" :items="sfItems" outlined :label="parameter.Name"
                              class="paramtextfield" dense disabled></v-select>
                            <v-text-field
                              v-else-if="parameter.Type === 'BitField' && parameter.Name != 'DefaultTransmissionDatarate'"
                              class="paramtextfield" v-model.number="parameter.ValueDecoded" outlined
                              :label="parameter.Name" @wheel="$event.target.blur()" type="number" dense disabled>
                            </v-text-field>
                            <v-checkbox v-else-if="parameter.Type === 'Bool'" v-model="parameter.Value"
                              class="paramtextfield" outlined :label="parameter.Name" dense disabled></v-checkbox>
                            <v-text-field v-else class="paramtextfield" v-model.number="parameter.Value"
                              @wheel="$event.target.blur()" type="number" outlined :label="parameter.Name" dense
                              disabled></v-text-field>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header outlined style="padding:0px 0px 5px 0px"><span
                          style="margin-left: 10px;">Fuses</span></v-expansion-panel-header>
                      <v-expansion-panel-content style="padding-top: 20px">
                        <v-row>
                          <v-text-field v-model="insertedUserpage.Fuses.BOD1v8Action" outlined label="BOD1v8Action"
                            class="paramtextfield" dense disabled></v-text-field>
                        </v-row>
                        <v-row>
                          <v-checkbox v-model="insertedUserpage.Fuses.BOD1V8Enabled" outlined label="BOD1V8Enabled"
                            class="paramtextfield" dense disabled></v-checkbox>
                          <v-checkbox v-model="insertedUserpage.Fuses.BOD1v8HystEnabled" outlined
                            label="BOD1v8HystEnabled" class="paramtextfield" dense disabled></v-checkbox>
                        </v-row>
                        <v-row>
                          <v-text-field v-model.number="insertedUserpage.Fuses.BOD1v8Level"
                            @wheel="$event.target.blur()" outlined label="BOD1v8Level" class="paramtextfield" dense
                            disabled></v-text-field>
                        </v-row>
                        <v-row>
                          <v-text-field v-model="insertedUserpage.Fuses.BOD3v3Action" outlined label="BOD3v3Action"
                            class="paramtextfield" dense disabled></v-text-field>
                        </v-row>
                        <v-row>
                          <v-checkbox v-model="insertedUserpage.Fuses.BOD3v3Enabled" outlined label="BOD3v3Enabled"
                            class="paramtextfield" dense disabled></v-checkbox>
                          <v-checkbox v-model="insertedUserpage.Fuses.BOD3v3HystEnabled" outlined
                            label="BOD3v3HystEnabled" class="paramtextfield" dense disabled></v-checkbox>
                        </v-row>
                        <v-row>
                          <v-text-field v-model.number="insertedUserpage.Fuses.BOD3v3Level"
                            @wheel="$event.target.blur()" outlined label="BOD3v3Level" class="paramtextfield" dense
                            disabled></v-text-field>
                        </v-row>
                        <v-row>
                          <v-checkbox v-model="insertedUserpage.Fuses.WDTAutoEnabled" outlined label="WDTAutoEnabled"
                            class="paramtextfield" dense disabled></v-checkbox>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </td>
              </template>
            </v-data-table>
          </v-card>
        </div>
      </v-layout>
    </v-container>

    <v-dialog v-model="printDialog" width="70%">
      <v-form ref="printForm">
        <v-card>
          <v-card-title class="headline lighten-2" primary-title>{{ selectedUserpages.length }} Stickers printen
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field v-model="printedVersion" outlined label="Print versie"></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field v-model="printedIndex" type="number" outlined label="Start index"></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="printSticker()">Printen</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <!-- Insert multiple userpages -->
    <v-dialog v-model="csvDialog" width="70%">
      <v-form ref="insertMultipleForm" v-model="valid">
        <v-overlay v-if="loadInsertBulk" :value="loadInsertBulk">
          <v-progress-circular :size="64" indeterminate></v-progress-circular>
        </v-overlay>
        <v-stepper v-model="bulkStepper" v-else>
          <v-stepper-header>
            <v-stepper-step :complete="bulkStepper > 1" step="1">AppEUI</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="bulkStepper > 2" step="2">Aantal</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="3">Userpage</v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card class="mb-12">
                <v-card-text>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-select @keyup.enter="toStepper2()" v-model="itemGroup" outlined label="App EUI"
                        :items="macadresses.map(m => m.label)" :rules="selectRules" required></v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-btn color="primary" v-if="itemGroup != null" @click="toStepper2()">Volgende</v-btn>
              <v-btn color="primary" text @click="csvDialog = false">Sluiten</v-btn>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-card class="mb-12">
                <v-card-text>
                  <v-row>
                    <v-col cols="12" sm="6" md="8">
                      <v-text-field @keyup.enter="bulkStepper = 3" v-model="userpageAmount" outlined
                        label="Aantal userpages" type="number" @wheel="$event.target.blur()" :rules="digitRules">
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-btn color="primary" @click="bulkStepper = 3">Volgende</v-btn>
              <v-btn @click="bulkStepper = 1" text>Vorige</v-btn>
              <v-btn color="primary" text @click="csvDialog = false">Sluiten</v-btn>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-card class="mb-12">
                <v-card-text>
                  <v-expansion-panels accordion>
                    <v-expansion-panel>
                      <v-row style="padding:0px 16px;">
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field v-model="insertedUserpages.Name" :rules="userpageNameRules" outlined
                            label="Name">
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="8"></v-col>
                      </v-row>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header style="padding:0px 16px;">
                        <v-select v-on:input="changeServiceSelection('datamultiple')"
                          v-model="insertedUserpages.DataService.Name" :rules="selectRules"
                          :items="servicesByType(insertedUserpages.DataService.Type)" item-text="name" outlined
                          label="Data Service" style="margin-left: 10px; padding-top: 30px"></v-select>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content style="padding-top: 12px;" eager>
                        <v-row v-for="(parameter, parIndex) in this.insertedUserpages.DataService.Parameters"
                          :key="parIndex">
                          <v-col cols="12" sm="6" md="4" class="paramcol" v-if="parameter.Configurable == true">
                            <v-text-field v-if="parameter.Type == 'Array'" v-mask="'NN '.repeat(parameter.ArrayLength)"
                              v-model="parameter.Value" class="paramtextfield" outlined :label="parameter.Name" dense>
                            </v-text-field>
                            <v-select
                              v-else-if="parameter.Type === 'BitField' && parameter.Name === 'DefaultTransmissionDatarate'"
                              v-model="parameter.ValueDecoded" :items="sfItems" outlined :label="parameter.Name"
                              class="paramtextfield" dense></v-select>
                            <v-text-field
                              v-else-if="parameter.Type === 'BitField' && parameter.Name != 'DefaultTransmissionDatarate'"
                              class="paramtextfield" v-model.number="parameter.ValueDecoded" outlined
                              :label="parameter.Name" @wheel="$event.target.blur()" type="number" :rules="digitRules"
                              dense>
                            </v-text-field>
                            <v-checkbox v-else-if="parameter.Type === 'Bool'" v-model="parameter.Value"
                              class="paramtextfield" outlined :label="parameter.Name" dense>
                            </v-checkbox>
                            <v-text-field v-else class="paramtextfield" v-model.number="parameter.Value"
                              @wheel="$event.target.blur()" type="number" outlined :label="parameter.Name" dense>
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                  <v-expansion-panels accordion>
                    <v-expansion-panel>
                      <v-expansion-panel-header style="padding:0px 16px;">Advanced</v-expansion-panel-header>
                      <v-expansion-panels>
                        <v-expansion-panel-content style="padding-top: 12px; width: 100%;" eager>
                          <v-expansion-panel>
                            <v-expansion-panel-header style="padding:0px 16px;">
                              <v-select v-on:input="changeServiceSelection('communicationmultiple')"
                                v-model="insertedUserpages.CommunicationService.Name" :rules="selectRules"
                                :items="servicesByType(insertedUserpages.CommunicationService.Type)" item-text="name"
                                outlined label="Communication Service" style="margin-left: 10px; padding-top: 30px">
                              </v-select>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content style="padding-top: 12px;" eager>
                              <v-row
                                v-for="(parameter, parIndex) in this.insertedUserpages.CommunicationService.Parameters"
                                :key="parIndex">
                                <v-col cols="12" sm="6" md="4" class="paramcol" v-if="parameter.Configurable == true">
                                  <v-text-field
                                    v-if="parameter.Type == 'Array' && (parameter.Name == 'DeviceEUI' || parameter.Name == 'ApplicationEUI' || parameter.Name == 'ApplicationKey')"
                                    v-mask="'NN '.repeat(parameter.ArrayLength)" v-model="parameter.Value"
                                    class="paramtextfield" outlined :label="parameter.Name" dense disabled>
                                  </v-text-field>
                                  <v-text-field v-else-if="parameter.Type == 'Array'"
                                    v-mask="'NN '.repeat(parameter.ArrayLength)" v-model="parameter.Value"
                                    class="paramtextfield" outlined :label="parameter.Name" dense></v-text-field>
                                  <v-select
                                    v-else-if="parameter.Type === 'BitField' && parameter.Name === 'DefaultTransmissionDatarate'"
                                    v-model="parameter.ValueDecoded" :items="sfItems" outlined :label="parameter.Name"
                                    class="paramtextfield" dense></v-select>
                                  <v-text-field
                                    v-else-if="parameter.Type === 'BitField' && parameter.Name != 'DefaultTransmissionDatarate'"
                                    class="paramtextfield" v-model.number="parameter.ValueDecoded" outlined
                                    :label="parameter.Name" @wheel="$event.target.blur()" type="number" dense>
                                  </v-text-field>
                                  <v-checkbox v-else-if="parameter.Type === 'Bool'" v-model="parameter.Value"
                                    class="paramtextfield" outlined :label="parameter.Name" dense></v-checkbox>
                                  <v-text-field v-else class="paramtextfield" v-model.number="parameter.Value"
                                    @wheel="$event.target.blur()" type="number" outlined :label="parameter.Name" dense>
                                  </v-text-field>
                                </v-col>
                              </v-row>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                          <v-expansion-panel>
                            <v-expansion-panel-header style="padding:0px 16px;">
                              <v-select v-on:input="changeServiceSelection('sleepmultiple')"
                                v-model="insertedUserpages.SleepService.Name" :rules="selectRules"
                                :items="servicesByType(insertedUserpages.SleepService.Type)" item-text="name" outlined
                                label="Sleep Service" style="margin-left: 10px; padding-top: 30px"></v-select>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content style="padding-top: 12px;">
                              <v-row v-for="(parameter, parIndex) in this.insertedUserpages.SleepService.Parameters"
                                :key="parIndex">
                                <v-col cols="12" sm="6" md="4" class="paramcol" v-if="parameter.Configurable == true">
                                  <v-text-field v-if="parameter.Type == 'Array'"
                                    v-mask="'NN '.repeat(parameter.ArrayLength)" v-model="parameter.Value"
                                    class="paramtextfield" outlined :label="parameter.Name" dense></v-text-field>
                                  <v-select
                                    v-else-if="parameter.Type === 'BitField' && parameter.Name === 'DefaultTransmissionDatarate'"
                                    v-model="parameter.ValueDecoded" :items="sfItems" outlined :label="parameter.Name"
                                    class="paramtextfield" dense></v-select>
                                  <v-text-field
                                    v-else-if="parameter.Type === 'BitField' && parameter.Name != 'DefaultTransmissionDatarate'"
                                    class="paramtextfield" v-model.number="parameter.ValueDecoded" outlined
                                    :label="parameter.Name" @wheel="$event.target.blur()" type="number" dense>
                                  </v-text-field>
                                  <v-checkbox v-else-if="parameter.Type === 'Bool'" v-model="parameter.Value"
                                    class="paramtextfield" outlined :label="parameter.Name" dense></v-checkbox>
                                  <v-text-field v-else class="paramtextfield" v-model.number="parameter.Value"
                                    @wheel="$event.target.blur()" type="number" outlined :label="parameter.Name" dense>
                                  </v-text-field>
                                </v-col>
                              </v-row>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                          <v-expansion-panel>
                            <v-expansion-panel-header style="padding:0px 16px;">
                              <v-select v-on:input="changeServiceSelection('timermultiple')"
                                v-model="insertedUserpages.TimerService.Name" :rules="selectRules"
                                :items="servicesByType(insertedUserpages.TimerService.Type)" item-text="name" outlined
                                label="Timer Service" style="margin-left: 10px; padding-top: 30px"></v-select>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content style="padding-top: 12px;" eager>
                              <v-row v-for="(parameter, parIndex) in this.insertedUserpages.TimerService.Parameters"
                                :key="parIndex">
                                <v-col cols="12" sm="6" md="4" class="paramcol" v-if="parameter.Configurable == true">
                                  <v-text-field v-if="parameter.Type == 'Array'"
                                    v-mask="'NN '.repeat(parameter.ArrayLength)" v-model="parameter.Value"
                                    class="paramtextfield" outlined :label="parameter.Name" dense></v-text-field>
                                  <v-select
                                    v-else-if="parameter.Type === 'BitField' && parameter.Name === 'DefaultTransmissionDatarate'"
                                    v-model="parameter.ValueDecoded" :items="sfItems" outlined :label="parameter.Name"
                                    class="paramtextfield" dense></v-select>
                                  <v-text-field
                                    v-else-if="parameter.Type === 'BitField' && parameter.Name != 'DefaultTransmissionDatarate'"
                                    class="paramtextfield" v-model.number="parameter.ValueDecoded" outlined
                                    :label="parameter.Name" @wheel="$event.target.blur()" type="number" dense>
                                  </v-text-field>
                                  <v-checkbox v-else-if="parameter.Type === 'Bool'" v-model="parameter.Value"
                                    class="paramtextfield" outlined :label="parameter.Name" dense></v-checkbox>
                                  <v-text-field v-else class="paramtextfield" v-model.number="parameter.Value"
                                    @wheel="$event.target.blur()" type="number" outlined :label="parameter.Name" dense>
                                  </v-text-field>
                                </v-col>
                              </v-row>
                            </v-expansion-panel-content>
                            <v-expansion-panel-header outlined style="padding:0px 0px 5px 0px"><span
                                style="margin-left: 10px;">Fuses</span></v-expansion-panel-header>
                            <v-expansion-panel-content style="padding-top: 20px" eager>
                              <v-row>
                                <v-text-field v-model="insertedUserpage.Fuses.BOD1v8Action" outlined
                                  label="BOD1v8Action" class="paramtextfield" dense></v-text-field>
                              </v-row>
                              <v-row>
                                <v-checkbox v-model="insertedUserpage.Fuses.BOD1V8Enabled" outlined
                                  label="BOD1V8Enabled" class="paramtextfield" dense></v-checkbox>
                                <v-checkbox v-model="insertedUserpage.Fuses.BOD1v8HystEnabled" outlined
                                  label="BOD1v8HystEnabled" class="paramtextfield" dense></v-checkbox>
                              </v-row>
                              <v-row>
                                <v-text-field v-model.number="insertedUserpage.Fuses.BOD1v8Level"
                                  @wheel="$event.target.blur()" outlined label="BOD1v8Level" class="paramtextfield"
                                  dense></v-text-field>
                              </v-row>
                              <v-row>
                                <v-text-field v-model="insertedUserpage.Fuses.BOD3v3Action" outlined
                                  label="BOD3v3Action" class="paramtextfield" dense></v-text-field>
                              </v-row>
                              <v-row>
                                <v-checkbox v-model="insertedUserpage.Fuses.BOD3v3Enabled" outlined
                                  label="BOD3v3Enabled" class="paramtextfield" dense></v-checkbox>
                                <v-checkbox v-model="insertedUserpage.Fuses.BOD3v3HystEnabled" outlined
                                  label="BOD3v3HystEnabled" class="paramtextfield" dense></v-checkbox>
                              </v-row>
                              <v-row>
                                <v-text-field v-model.number="insertedUserpage.Fuses.BOD3v3Level"
                                  @wheel="$event.target.blur()" outlined label="BOD3v3Level" class="paramtextfield"
                                  dense></v-text-field>
                              </v-row>
                              <v-row>
                                <v-checkbox v-model="insertedUserpage.Fuses.WDTAutoEnabled" outlined
                                  label="WDTAutoEnabled" class="paramtextfield" dense></v-checkbox>
                              </v-row>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panel-content>
                      </v-expansion-panels>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-card-text>
              </v-card>
              <v-alert v-model="userpageInsertFailedAlert" type="error" transition="fade-transition" dismissible>
                Aanmaken
                van
                userpage is mislukt. {{ userpageInsertFailedMessage }}</v-alert>
              <v-btn color="primary" @click="validateInsertUserpages()">Genereer</v-btn>
              <v-btn @click="bulkStepper = 2" text>Vorige</v-btn>
              <v-btn color="primary" text @click="csvDialog = false">Sluiten</v-btn>
              <v-checkbox v-model="do_chirpstack_generation"
                label="Genereer chirpstack devices in productie-wachtruimte" class="paramtextfield" dense></v-checkbox>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-form>
    </v-dialog>

    <!-- Insert single userpage -->
    <v-dialog v-model="insertDialog" width="70%">
      <v-form ref="insertSingleForm" v-model="valid">
        <v-card>
          <v-card-title class="headline lighten-2" primary-title>Userpage toevoegen</v-card-title>
          <v-card-text>
            <v-expansion-panels accordion>
              <v-expansion-panel>
                <v-row style="padding:0px 16px;">
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="insertedUserpage.Name" required :rules="userpageNameRules" outlined
                      label="Name">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="8">
                  </v-col>
                </v-row>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header style="padding:0px 16px;">
                  <v-select v-on:input="changeServiceSelection('datasingle')"
                    v-model="insertedUserpage.DataService.Name" :rules="selectRules"
                    :items="servicesByType(insertedUserpage.DataService.Type)" item-text="name" outlined
                    label="Data Service" style="margin-left: 10px; padding-top: 30px"></v-select>
                </v-expansion-panel-header>
                <v-expansion-panel-content style="padding-top: 12px;" eager>
                  <v-row v-for="(parameter, parIndex) in this.insertedUserpage.DataService.Parameters" :key="parIndex">
                    <v-col cols="12" sm="6" md="4" class="paramcol" v-if="parameter.Configurable == true">
                      <v-text-field v-if="parameter.Type == 'Array'" v-mask="'NN '.repeat(parameter.ArrayLength)"
                        v-model="parameter.Value" class="paramtextfield" outlined :label="parameter.Name" dense>
                      </v-text-field>
                      <v-select
                        v-else-if="parameter.Type === 'BitField' && parameter.Name === 'DefaultTransmissionDatarate'"
                        v-model="parameter.ValueDecoded" :items="sfItems" outlined :label="parameter.Name"
                        class="paramtextfield" dense></v-select>
                      <v-text-field
                        v-else-if="parameter.Type === 'BitField' && parameter.Name != 'DefaultTransmissionDatarate'"
                        class="paramtextfield" v-model.number="parameter.ValueDecoded" outlined :label="parameter.Name"
                        @wheel="$event.target.blur()" type="number" dense :rules="digitRules"></v-text-field>
                      <v-checkbox v-else-if="parameter.Type === 'Bool'" v-model="parameter.Value" class="paramtextfield"
                        outlined :label="parameter.Name" dense></v-checkbox>
                      <v-text-field v-else class="paramtextfield" v-model.number="parameter.Value"
                        @wheel="$event.target.blur()" type="number" outlined :label="parameter.Name" dense>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-expansion-panels accordion>
              <v-expansion-panel>
                <v-expansion-panel-header style="padding:0px 16px;">Advanced</v-expansion-panel-header>
                <v-expansion-panels>
                  <v-expansion-panel-content style="padding-top: 12px; width: 100%;" eager>
                    <v-expansion-panel>
                      <v-expansion-panel-header style="padding:0px 16px;">
                        <v-select v-on:input="changeServiceSelection('communicationsingle')"
                          v-model="insertedUserpage.CommunicationService.Name" :rules="selectRules"
                          :items="servicesByType(insertedUserpage.CommunicationService.Type)" item-text="name" outlined
                          label="Communication Service" style="margin-left: 10px; padding-top: 30px"></v-select>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content style="padding-top: 12px;" eager>
                        <v-row v-for="(parameter, parIndex) in this.insertedUserpage.CommunicationService.Parameters"
                          :key="parIndex">
                          <v-col cols="12" sm="6" md="4" class="paramcol" v-if="parameter.Configurable == true">
                            <v-text-field v-if="parameter.Type == 'Array'" v-mask="'NN '.repeat(parameter.ArrayLength)"
                              v-model="parameter.Value" class="paramtextfield" outlined :label="parameter.Name" dense
                              required :rules="appEuiRules">
                            </v-text-field>
                            <v-select
                              v-else-if="parameter.Type === 'BitField' && parameter.Name === 'DefaultTransmissionDatarate'"
                              v-model="parameter.ValueDecoded" :items="sfItems" outlined :label="parameter.Name"
                              class="paramtextfield" dense></v-select>
                            <v-text-field
                              v-else-if="parameter.Type === 'BitField' && parameter.Name != 'DefaultTransmissionDatarate'"
                              class="paramtextfield" v-model.number="parameter.ValueDecoded" outlined
                              :label="parameter.Name" @wheel="$event.target.blur()" type="number" dense
                              :rules="digitRules"></v-text-field>
                            <v-checkbox v-else-if="parameter.Type === 'Bool'" v-model="parameter.Value"
                              class="paramtextfield" outlined :label="parameter.Name" dense></v-checkbox>
                            <v-text-field v-else class="paramtextfield" v-model.number="parameter.Value"
                              @wheel="$event.target.blur()" type="number" outlined :label="parameter.Name" dense>
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header style="padding:0px 16px;">
                        <v-select v-on:input="changeServiceSelection('sleepsingle')"
                          v-model="insertedUserpage.SleepService.Name" :rules="selectRules"
                          :items="servicesByType(insertedUserpage.SleepService.Type)" item-text="name" outlined
                          label="Sleep Service" style="margin-left: 10px; padding-top: 30px"></v-select>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content style="padding-top: 12px;" eager>
                        <v-row v-for="(parameter, parIndex) in this.insertedUserpage.SleepService.Parameters"
                          :key="parIndex">
                          <v-col cols="12" sm="6" md="4" class="paramcol" v-if="parameter.Configurable == true">
                            <v-text-field v-if="parameter.Type == 'Array'" v-mask="'NN '.repeat(parameter.ArrayLength)"
                              v-model="parameter.Value" class="paramtextfield" outlined :label="parameter.Name" dense
                              required :rules="appEuiRules"></v-text-field>
                            <v-select
                              v-else-if="parameter.Type === 'BitField' && parameter.Name === 'DefaultTransmissionDatarate'"
                              v-model="parameter.ValueDecoded" :items="sfItems" outlined :label="parameter.Name"
                              class="paramtextfield" dense></v-select>
                            <v-text-field
                              v-else-if="parameter.Type === 'BitField' && parameter.Name != 'DefaultTransmissionDatarate'"
                              class="paramtextfield" v-model.number="parameter.ValueDecoded" outlined
                              :label="parameter.Name" @wheel="$event.target.blur()" type="number" dense
                              :rules="digitRules"></v-text-field>
                            <v-checkbox v-else-if="parameter.Type === 'Bool'" v-model="parameter.Value"
                              class="paramtextfield" outlined :label="parameter.Name" dense></v-checkbox>
                            <v-text-field v-else class="paramtextfield" v-model.number="parameter.Value"
                              @wheel="$event.target.blur()" type="number" outlined :label="parameter.Name" dense>
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header style="padding:0px 16px;">
                        <v-select v-on:input="changeServiceSelection('timersingle')"
                          v-model="insertedUserpage.TimerService.Name" :rules="selectRules"
                          :items="servicesByType(insertedUserpage.TimerService.Type)" item-text="name" outlined
                          label="Timer Service" style="margin-left: 10px; padding-top: 30px"></v-select>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content style="padding-top: 12px;" eager>
                        <v-row v-for="(parameter, parIndex) in this.insertedUserpage.TimerService.Parameters"
                          :key="parIndex">
                          <v-col cols="12" sm="6" md="4" class="paramcol" v-if="parameter.Configurable == true">
                            <v-text-field v-if="parameter.Type == 'Array'" v-mask="'NN '.repeat(parameter.ArrayLength)"
                              v-model="parameter.Value" class="paramtextfield" outlined :label="parameter.Name" dense
                              required :rules="appEuiRules"></v-text-field>
                            <v-select
                              v-else-if="parameter.Type === 'BitField' && parameter.Name === 'DefaultTransmissionDatarate'"
                              v-model="parameter.ValueDecoded" :items="sfItems" outlined :label="parameter.Name"
                              class="paramtextfield" dense></v-select>
                            <v-text-field
                              v-else-if="parameter.Type === 'BitField' && parameter.Name != 'DefaultTransmissionDatarate'"
                              class="paramtextfield" v-model.number="parameter.ValueDecoded" outlined
                              :label="parameter.Name" @wheel="$event.target.blur()" type="number" dense
                              :rules="digitRules"></v-text-field>
                            <v-checkbox v-else-if="parameter.Type === 'Bool'" v-model="parameter.Value"
                              class="paramtextfield" outlined :label="parameter.Name" dense></v-checkbox>
                            <v-text-field v-else class="paramtextfield" v-model.number="parameter.Value"
                              @wheel="$event.target.blur()" type="number" outlined :label="parameter.Name" dense>
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                      <v-expansion-panel-header outlined style="padding:0px 0px 5px 0px"><span
                          style="margin-left: 10px;">Fuses</span></v-expansion-panel-header>
                      <v-expansion-panel-content style="padding-top: 20px" eager>
                        <v-row>
                          <v-text-field v-model="insertedUserpage.Fuses.BOD1v8Action" outlined label="BOD1v8Action"
                            class="paramtextfield" dense></v-text-field>
                        </v-row>
                        <v-row>
                          <v-checkbox v-model="insertedUserpage.Fuses.BOD1V8Enabled" outlined label="BOD1V8Enabled"
                            class="paramtextfield" dense></v-checkbox>
                          <v-checkbox v-model="insertedUserpage.Fuses.BOD1v8HystEnabled" outlined
                            label="BOD1v8HystEnabled" class="paramtextfield" dense></v-checkbox>
                        </v-row>
                        <v-row>
                          <v-text-field v-model.number="insertedUserpage.Fuses.BOD1v8Level"
                            @wheel="$event.target.blur()" outlined label="BOD1v8Level" class="paramtextfield" dense>
                          </v-text-field>
                        </v-row>
                        <v-row>
                          <v-text-field v-model="insertedUserpage.Fuses.BOD3v3Action" outlined label="BOD3v3Action"
                            class="paramtextfield" dense></v-text-field>
                        </v-row>
                        <v-row>
                          <v-checkbox v-model="insertedUserpage.Fuses.BOD3v3Enabled" outlined label="BOD3v3Enabled"
                            class="paramtextfield" dense></v-checkbox>
                          <v-checkbox v-model="insertedUserpage.Fuses.BOD3v3HystEnabled" outlined
                            label="BOD3v3HystEnabled" class="paramtextfield" dense></v-checkbox>
                        </v-row>
                        <v-row>
                          <v-text-field v-model.number="insertedUserpage.Fuses.BOD3v3Level"
                            @wheel="$event.target.blur()" outlined label="BOD3v3Level" class="paramtextfield" dense>
                          </v-text-field>
                        </v-row>
                        <v-row>
                          <v-checkbox v-model="insertedUserpage.Fuses.WDTAutoEnabled" outlined label="WDTAutoEnabled"
                            class="paramtextfield" dense></v-checkbox>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panel-content>
                </v-expansion-panels>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
          <v-alert v-model="userpageInsertFailedAlert" type="error" transition="fade-transition" dismissible> Aanmaken
            van
            userpage is mislukt. {{ userpageInsertFailedMessage }}</v-alert>
          <v-btn style="margin-left: 10px; margin-top:10px" color="primary"
            @click="validateInsertUserpage()">Toevoegen</v-btn>
          <v-btn color="primary" text style="margin-left: 10px; margin-top:10px"
            @click="insertDialog = false">Sluiten</v-btn>
          <p style="color:#ff5252; margin-left: 10px; margin-top:15px">{{ invalidFields }}</p>
          <v-checkbox style="margin-left: 10px" v-model="do_chirpstack_generation_single"
            label="Genereer chirpstack devices in productie-wachtruimte" class="paramtextfield" dense></v-checkbox>
        </v-card>
      </v-form>
    </v-dialog>

    <!-- Edit single userpage -->
    <v-dialog v-model="editDialog" width="70%">
      <v-form lazy-validation ref="editSingleForm" v-model="valid">
        <v-card>
          <v-card-title class="headline lighten-2" primary-title>Userpage wijzigen</v-card-title>
          <v-card-text>
            <v-expansion-panels accordion>
              <v-expansion-panel>
                <v-row v-if="editDialog"
                  style="padding-top: 0px; padding-bottom: 0px; padding-left:24px; padding-right:24px;">
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedUserpage.Name" :rules="userpageNameRules" outlined label="Name">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-select v-model="editedUserpage.status_id" :clearable="false" :items="statusList" item-value="id"
                      item-text="name" outlined label="Status">
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="8">
                    <v-select v-model="editedUserpage.CommunicationService.Type" outlined
                      :items="['BasicCommunicationService', 'IntervalCommunicationService', 'ContinuousCommunicationService']"
                      item-text="name" label="Service Manager"></v-select>
                  </v-col>
                </v-row>
                <v-expansion-panel-header outlined style="padding:0px 0px 5px 0px"><span
                    style="margin-left: 10px;">Fuses</span></v-expansion-panel-header>
                <v-expansion-panel-content style="padding-top: 20px" eager>
                  <v-row>
                    <v-text-field v-model="editedUserpage.Fuses.BOD1v8Action" outlined label="BOD1v8Action"
                      class="paramtextfield" dense></v-text-field>
                  </v-row>
                  <v-row>
                    <v-checkbox v-model="editedUserpage.Fuses.BOD1V8Enabled" outlined label="BOD1V8Enabled"
                      class="paramtextfield" dense></v-checkbox>
                    <v-checkbox v-model="editedUserpage.Fuses.BOD1v8HystEnabled" outlined label="BOD1v8HystEnabled"
                      class="paramtextfield" dense></v-checkbox>
                  </v-row>
                  <v-row>
                    <v-text-field v-model.number="editedUserpage.Fuses.BOD1v8Level" @wheel="$event.target.blur()"
                      outlined label="BOD1v8Level" class="paramtextfield" dense></v-text-field>
                  </v-row>
                  <v-row>
                    <v-text-field v-model="editedUserpage.Fuses.BOD3v3Action" outlined label="BOD3v3Action"
                      class="paramtextfield" dense></v-text-field>
                  </v-row>
                  <v-row>
                    <v-checkbox v-model="editedUserpage.Fuses.BOD3v3Enabled" outlined label="BOD3v3Enabled"
                      class="paramtextfield" dense></v-checkbox>
                    <v-checkbox v-model="editedUserpage.Fuses.BOD3v3HystEnabled" outlined label="BOD3v3HystEnabled"
                      class="paramtextfield" dense></v-checkbox>
                  </v-row>
                  <v-row>
                    <v-text-field v-model.number="editedUserpage.Fuses.BOD3v3Level" @wheel="$event.target.blur()"
                      outlined label="BOD3v3Level" class="paramtextfield" dense></v-text-field>
                  </v-row>
                  <v-row>
                    <v-checkbox v-model="editedUserpage.Fuses.WDTAutoEnabled" outlined label="WDTAutoEnabled"
                      class="paramtextfield" dense></v-checkbox>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header style="padding:0px 16px;">
                  <v-select v-on:input="changeServiceSelection('communicationsingle')"
                    v-model="editedUserpage.CommunicationService.Name" :rules="selectRules"
                    :items="servicesByType(editedUserpage.CommunicationService.Type)" item-text="name" outlined
                    label="Communication Service" style="margin-left: 10px; padding-top: 30px"></v-select>
                </v-expansion-panel-header>
                <v-expansion-panel-content style="padding-top: 12px;" eager>
                  <v-row v-for="(parameter, parIndex) in this.editedUserpage.CommunicationService.Parameters"
                    :key="parIndex">
                    <v-col cols="12" sm="6" md="3" class="paramcol" v-if="parameter.Configurable == true">
                      <v-text-field v-if="parameter.Type == 'Array'" v-mask="'NN '.repeat(parameter.ArrayLength)"
                        v-model="parameter.Value" class="paramtextfield" outlined :label="parameter.Name" dense
                        :rules="appEuiRules">
                      </v-text-field>
                      <v-select
                        v-else-if="parameter.Type === 'BitField' && parameter.Name === 'DefaultTransmissionDatarate'"
                        v-model="parameter.ValueDecoded" :items="sfItems" outlined :label="parameter.Name"
                        class="paramtextfield" dense></v-select>
                      <v-text-field
                        v-else-if="parameter.Type === 'BitField' && parameter.Name != 'DefaultTransmissionDatarate'"
                        class="paramtextfield" v-model.number="parameter.ValueDecoded" outlined :label="parameter.Name"
                        @wheel="$event.target.blur()" type="number" :rules="digitRules" dense></v-text-field>
                      <v-checkbox v-else-if="parameter.Type === 'Bool'" v-model="parameter.Value" class="paramtextfield"
                        outlined :label="parameter.Name" dense></v-checkbox>
                      <v-text-field v-else class="paramtextfield" v-model.number="parameter.Value"
                        @wheel="$event.target.blur()" type="number" outlined :label="parameter.Name" dense>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header style="padding:0px 16px;">
                  <v-select v-on:input="changeServiceSelection('datasingle')" v-model="editedUserpage.DataService.Name"
                    :rules="selectRules" :items="servicesByType(editedUserpage.DataService.Type)" item-text="name"
                    outlined label="Data Service" style="margin-left: 10px; padding-top: 30px"></v-select>
                </v-expansion-panel-header>
                <v-expansion-panel-content style="padding-top: 12px;" eager>
                  <v-row v-for="(parameter, parIndex) in this.editedUserpage.DataService.Parameters" :key="parIndex">
                    <v-col cols="12" sm="6" md="4" class="paramcol" v-if="parameter.Configurable == true">
                      <v-text-field v-if="parameter.Type == 'Array'" v-mask="'NN '.repeat(parameter.ArrayLength)"
                        v-model="parameter.Value" class="paramtextfield" outlined :label="parameter.Name" dense>
                      </v-text-field>
                      <v-select
                        v-else-if="parameter.Type === 'BitField' && parameter.Name === 'DefaultTransmissionDatarate'"
                        v-model="parameter.ValueDecoded" :items="sfItems" outlined :label="parameter.Name"
                        class="paramtextfield" dense></v-select>
                      <v-text-field
                        v-else-if="parameter.Type === 'BitField' && parameter.Name != 'DefaultTransmissionDatarate'"
                        class="paramtextfield" v-model.number="parameter.ValueDecoded" outlined :label="parameter.Name"
                        @wheel="$event.target.blur()" type="number" dense></v-text-field>
                      <v-checkbox v-else-if="parameter.Type === 'Bool'" v-model="parameter.Value" class="paramtextfield"
                        outlined :label="parameter.Name" dense></v-checkbox>
                      <v-text-field v-else class="paramtextfield" v-model.number="parameter.Value"
                        @wheel="$event.target.blur()" type="number" outlined :label="parameter.Name" dense>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header style="padding:0px 16px;">
                  <v-select v-on:input="changeServiceSelection('sleepsingle')"
                    v-model="editedUserpage.SleepService.Name" :rules="selectRules"
                    :items="servicesByType(editedUserpage.SleepService.Type)" item-text="name" outlined
                    label="Sleep Service" style="margin-left: 10px; padding-top: 30px"></v-select>
                </v-expansion-panel-header>
                <v-expansion-panel-content style="padding-top: 12px;" eager>
                  <v-row v-for="(parameter, parIndex) in this.editedUserpage.SleepService.Parameters" :key="parIndex">
                    <v-col cols="12" sm="6" md="4" class="paramcol" v-if="parameter.Configurable == true">
                      <v-text-field v-if="parameter.Type == 'Array'" v-mask="'NN '.repeat(parameter.ArrayLength)"
                        v-model="parameter.Value" class="paramtextfield" outlined :label="parameter.Name"
                        dense></v-text-field>
                      <v-select
                        v-else-if="parameter.Type === 'BitField' && parameter.Name === 'DefaultTransmissionDatarate'"
                        v-model="parameter.ValueDecoded" :items="sfItems" outlined :label="parameter.Name"
                        class="paramtextfield" dense></v-select>
                      <v-text-field
                        v-else-if="parameter.Type === 'BitField' && parameter.Name != 'DefaultTransmissionDatarate'"
                        class="paramtextfield" v-model.number="parameter.ValueDecoded" outlined :label="parameter.Name"
                        @wheel="$event.target.blur()" type="number" dense></v-text-field>
                      <v-checkbox v-else-if="parameter.Type === 'Bool'" v-model="parameter.Value" class="paramtextfield"
                        outlined :label="parameter.Name" dense></v-checkbox>
                      <v-text-field v-else class="paramtextfield" v-model.number="parameter.Value"
                        @wheel="$event.target.blur()" type="number" outlined :label="parameter.Name" dense>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header style="padding:0px 16px;">
                  <v-select v-on:input="changeServiceSelection('timersingle')"
                    v-model="editedUserpage.TimerService.Name" :rules="selectRules"
                    :items="servicesByType(editedUserpage.TimerService.Type)" item-text="name" outlined
                    label="Timer Service" style="margin-left: 10px; padding-top: 30px"></v-select>
                </v-expansion-panel-header>
                <v-expansion-panel-content style="padding-top: 12px;" eager>
                  <v-row v-for="(parameter, parIndex) in this.editedUserpage.TimerService.Parameters" :key="parIndex">
                    <v-col cols="12" sm="6" md="4" class="paramcol" v-if="parameter.Configurable == true">
                      <v-text-field v-if="parameter.Type == 'Array'" v-mask="'NN '.repeat(parameter.ArrayLength)"
                        v-model="parameter.Value" class="paramtextfield" outlined :label="parameter.Name"
                        dense></v-text-field>
                      <v-select
                        v-else-if="parameter.Type === 'BitField' && parameter.Name === 'DefaultTransmissionDatarate'"
                        v-model="parameter.ValueDecoded" :items="sfItems" outlined :label="parameter.Name"
                        class="paramtextfield" dense></v-select>
                      <v-text-field
                        v-else-if="parameter.Type === 'BitField' && parameter.Name != 'DefaultTransmissionDatarate'"
                        class="paramtextfield" v-model.number="parameter.ValueDecoded" outlined :label="parameter.Name"
                        @wheel="$event.target.blur()" type="number" dense></v-text-field>
                      <v-checkbox v-else-if="parameter.Type === 'Bool'" v-model="parameter.Value" class="paramtextfield"
                        outlined :label="parameter.Name" dense></v-checkbox>
                      <v-text-field v-else class="paramtextfield" v-model.number="parameter.Value"
                        @wheel="$event.target.blur()" type="number" outlined :label="parameter.Name" dense>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
          <v-divider></v-divider>
          <v-spacer></v-spacer>
          <p style="color:#ff5252; margin-left: 10px; margin-top:15px">{{ invalidFields }}</p>
          <v-btn color="primary" style="margin-left: 10px; margin-top:10px"
            @click="validateEditUserpage()">Bijwerken</v-btn>
          <v-btn color="primary" text style="margin-left: 10px; margin-top:10px"
            @click="editDialog = false">Sluiten</v-btn>
          <v-spacer style="height:10px"></v-spacer>
        </v-card>
      </v-form>
    </v-dialog>

    <!-- Edit bulk userpage -->
    <v-dialog v-model="editBulkDialog" width="70%">
      <v-form ref="editBulkForm" v-model="validBulk">
        <v-card>
          <v-card-title class="headline lighten-2" primary-title>Userpage wijzigen</v-card-title>
          <v-card-text>
            <v-expansion-panels accordion>
              <v-expansion-panel>
                <v-row v-if="editBulkDialog"
                  style="padding-top: 0px; padding-bottom: 0px; padding-left:24px; padding-right:24px;">
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editBulkName" :rules="userpageNameRules" outlined
                      label="Bulk name"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-select v-model="editBulkStatusId" :items="bulkStatusList" item-value="id" item-text="name"
                      outlined label="Status">
                    </v-select>
                  </v-col>
                  <v-col v-if="dataservicesAdjective" cols="12" sm="6" md="4">
                    <v-checkbox v-model="enabledCommunicationService" label="Edit CommunicationService"
                      style="margin-top: 0px; margin-bottom: 0px"></v-checkbox>
                    <v-checkbox v-model="enabledDataService" label="Edit DataService"
                      style="margin-top: 0px; margin-bottom: 0px"></v-checkbox>
                    <v-checkbox v-model="changeAppEUI" label="Change AppEUI"
                      style="margin-top: 0px; margin-bottom: 0px"></v-checkbox>
                  </v-col>
                </v-row>
              </v-expansion-panel>
              <v-expansion-panel v-if="dataservicesAdjective" :disabled="!enabledCommunicationService" hide details>
                <v-expansion-panel-header style="padding: 0px 16px;">
                  <v-select v-on:input="changeServiceSelection('communicationsingle')"
                    v-model="editedUserpage.CommunicationService.Name" :rules="selectRules"
                    :items="servicesByType(editedUserpage.CommunicationService.Type)" item-text="name" outlined
                    label="Communication Service" style="margin-left: 10px; padding-top: 30px"></v-select>
                </v-expansion-panel-header>
                <v-expansion-panel-content style="padding-top: 12px;" eager>
                  <v-row v-for="(parameter, parIndex) in this.editedUserpage.CommunicationService.Parameters"
                    :key="parIndex">
                    <v-col cols="12" sm="6" md="3" class="paramcol" v-if="parameter.Configurable == true">
                      <v-text-field v-if="parameter.Type == 'Array'" v-mask="'NN '.repeat(parameter.ArrayLength)"
                        v-model="parameter.Value" class="paramtextfield" outlined :label="parameter.Name" dense
                        :rules="appEuiRules">
                      </v-text-field>
                      <v-select
                        v-else-if="parameter.Type === 'BitField' && parameter.Name === 'DefaultTransmissionDatarate'"
                        v-model="parameter.ValueDecoded" :items="sfItems" outlined :label="parameter.Name"
                        class="paramtextfield" dense></v-select>
                      <v-text-field
                        v-else-if="parameter.Type === 'BitField' && parameter.Name != 'DefaultTransmissionDatarate'"
                        class="paramtextfield" v-model.number="parameter.ValueDecoded" outlined :label="parameter.Name"
                        @wheel="$event.target.blur()" type="number" :rules="digitRules" dense></v-text-field>
                      <v-checkbox v-else-if="parameter.Type === 'Bool'" v-model="parameter.Value" class="paramtextfield"
                        outlined :label="parameter.Name" dense></v-checkbox>
                      <v-text-field v-else class="paramtextfield" v-model.number="parameter.Value"
                        @wheel="$event.target.blur()" type="number" outlined :label="parameter.Name" dense>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-card-text style="color:indianred; font-weight: bolder;" v-else> Er zijn verschillende data services
                geselecteerd, de communication service kan niet aangepast worden </v-card-text>
              <v-expansion-panel v-if="dataservicesAdjective" :disabled="!enabledDataService" hide details>
                <v-expansion-panel-header style="padding:0px 16px;">
                  <v-select v-on:input="changeServiceSelection('datasingle')" v-model="editedUserpage.DataService.Name"
                    :rules="selectRules" :items="servicesByType(editedUserpage.DataService.Type)" item-text="name"
                    outlined label="Data Service" style="margin-left: 10px; padding-top: 30px"></v-select>
                </v-expansion-panel-header>
                <v-expansion-panel-content style="padding-top: 12px;" eager>
                  <v-row v-for="(parameter, parIndex) in this.editedUserpage.DataService.Parameters" :key="parIndex">
                    <v-col cols="12" sm="6" md="4" class="paramcol" v-if="parameter.Configurable == true">
                      <v-text-field v-if="parameter.Type == 'Array'" v-mask="'NN '.repeat(parameter.ArrayLength)"
                        v-model="parameter.Value" class="paramtextfield" outlined :label="parameter.Name"
                        dense></v-text-field>
                      <v-select
                        v-else-if="parameter.Type === 'BitField' && parameter.Name === 'DefaultTransmissionDatarate'"
                        v-model="parameter.ValueDecoded" :items="sfItems" outlined :label="parameter.Name"
                        class="paramtextfield" dense></v-select>
                      <v-text-field
                        v-else-if="parameter.Type === 'BitField' && parameter.Name != 'DefaultTransmissionDatarate'"
                        class="paramtextfield" v-model.number="parameter.ValueDecoded" outlined :label="parameter.Name"
                        @wheel="$event.target.blur()" type="number" dense></v-text-field>
                      <v-checkbox v-else-if="parameter.Type === 'Bool'" v-model="parameter.Value" class="paramtextfield"
                        outlined :label="parameter.Name" dense></v-checkbox>
                      <v-text-field v-else class="paramtextfield" v-model.number="parameter.Value"
                        @wheel="$event.target.blur()" type="number" outlined :label="parameter.Name" dense>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-card-text style="color:indianred; font-weight: bolder;" v-else> Er zijn verschillende dataservices
                geselecteerd, de dataservice kan niet aangepast worden </v-card-text>
            </v-expansion-panels>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="primary" style="margin-left: 10px; margin-top:10px"
              @click="processEditUserpages()">Bijwerken</v-btn>
            <v-btn color="primary" text style="margin-left: 10px; margin-top:10px"
              @click="editBulkDialog = false">Sluiten</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import cryptoRandomString from "crypto-random-string"
import JSZip from "jszip"

export default {
  name: "Overview",
  data() {
    return {
      copySnackbar: false,
      dataservicesAdjective: false,
      communicationservicesAdjective: false,
      enabledCommunicationService: false,
      enabledDataService: false,
      changeAppEUI: false,
      printedVersion: "V1.00",
      invalidFields: "",
      printedIndex: 1,
      printDialog: false,
      valid: true,
      validBulk: true,
      generated: false,
      fetchedService: 'null',
      csvDialog: false,
      macadresses: [],
      editDialog: false,
      editBulkDialog: false,
      editBulkName: "",
      editBulkStatusId: -1,
      expanded: [],
      singleExpand: false,
      search: '',
      unknownErrorAlert: false,
      notFoundAlert: false,
      admin: false,
      userpages: [],
      selectedUserpages: [],
      showSelected: false,
      services: [],
      insertDialog: false,
      userpageInsertedAlert: false,
      userpageEditedAlert: false,
      userpageInsertFailedAlert: false,
      userpageInsertFailedMessage: "",
      userpageEditFailedAlert: false,
      sliderIncorrectAlert: false,
      userpagesInsertedAlert: false,
      userpagesInsertFailedAlert: false,
      genericSucces: false,
      genericSuccesMessage: "",
      genericAlert: false,
      genericAlertMessage: "",
      item: null,
      editedId: 0,
      bulkStepper: 1,
      itemGroup: null,
      subItemGroup: null,
      loadInsertBulk: false,
      loadUserpages: false,
      stringRules: [
        v => {
          if (v == null) { return 'Veld mag niet leeg zijn' }
          if (v.length == 0) { return 'Veld mag niet leeg zijn' }
          return true
        },
      ],
      selectRules: [
        v => {
          if (v == null) { return 'Veld mag niet leeg zijn' }
          if (v.length == 0) { return 'Veld mag niet leeg zijn' }
          return true
        },
      ],
      digitRules: [
        v => {
          if (v == null) { return 'Veld mag niet leeg zijn' }
          if (v.length == 0) { return 'Veld mag niet leeg zijn' }
          if (!String(v).replaceAll(" ", "").match("^-?[0-9]*$")) { return `Dit is geen geldig getal` }
          return true
        }
      ],
      numberRules: [
        v => {
          if (v == null) { return 'Veld mag niet leeg zijn' }
          if (v.length == 0) { return 'Veld mag niet leeg zijn' }
          if (!String(v).replaceAll(" ", "").match("^-?[0-9].{0,1}[0-9]*$")) { return `Dit is geen geldig getal` }
          return true
        }
      ],
      appEuiRules: [
        v => {
          if (v == null) { return 'Veld mag niet leeg zijn' }
          if (v.length == 0) { return 'Veld mag niet leeg zijn' }
          if (!String(v).replaceAll(" ", "").match("^([0-9A-Fa-f]{16}|[0-9A-Fa-f]{32})$")) { return `Deze EUI is niet geldig` }
          return true
        }
      ],
      userpageNameRules: [
        v => {
          if (v == null) { return 'Veld mag niet leeg zijn' }
          if (v.length == 0) { return 'Veld mag niet leeg zijn' }
          if (String(v).includes(" ")) { return `Er mogen geen spaties in de naam zitten` }
          return true
        }
      ],
      sfItems: [
        "SF12",
        "SF11",
        "SF10",
        "SF9",
        "SF8",
        "SF7"
      ],
      headers: [
        { text: "ID", align: "center", value: "id" },
        { text: "Naam", align: "center", value: "name" },
        { text: "Status", filterable: false, align: "center", value: "status_name" },
        { text: "DevEUI", align: "center", value: "devEui" },
        { text: "AppEUI", align: "center", value: "appEui" },
        { text: "AppKey", align: "center", value: "appKey" },
        { text: "Timestamp", align: "center", value: "timestamp" },
        { text: "Acties", filterable: false, value: "action", sortable: false }
      ],
      insertedUserpage: {
        Name: "",
        DevEUI: "00000000",
        AppEUI: "00000000",
        AppKey: "00000000",
        Fuses: {
          BOD1v8HystEnabled: false,
          BOD1v8Action: "None",
          BOD1v8Enabled: false,
          BOD1v8Level: 1.25,
          BOD3v3HystEnabled: false,
          BOD3v3Action: "None",
          BOD3v3Enabled: false,
          BOD3v3Level: 1.55,
          WDTAutoEnabled: false
        },
        CommunicationService: {
          Name: "LoRaWANClassACommunicationService",
          Type: "ContinuousCommunicationService",
          SupportsRamWipe: true,
          SleepWipesRam: true,
          Parameters: []
        },
        DataService: {
          Name: "",
          Type: "DataService",
          SupportsRamWipe: true,
          SleepWipesRam: true,
          Parameters: []
        },
        SleepService: {
          Name: "AtsamSleepService with Backup",
          Type: "SleepService",
          SupportsRamWipe: true,
          SleepWipesRam: true,
          Parameters: []
        },
        TimerService: {
          Name: "ASTTimerService",
          Type: "TimerService",
          SupportsRamWipe: true,
          SleepWipesRam: true,
          Parameters: []
        },
      },
      insertedUserpages: {
        Name: "",
        DevEUI: "00000000",
        AppEUI: "00000000",
        AppKey: "00000000",
        Group: "",
        Fuses: {
          BOD1v8HystEnabled: false,
          BOD1v8Action: "None",
          BOD1v8Enabled: false,
          BOD1v8Level: 1.25,
          BOD3v3HystEnabled: false,
          BOD3v3Action: "None",
          BOD3v3Enabled: false,
          BOD3v3Level: 1.55,
          WDTAutoEnabled: false
        },
        CommunicationService: {
          Name: "LoRaWANClassACommunicationService",
          Type: "ContinuousCommunicationService",
          SupportsRamWipe: true,
          SleepWipesRam: true,
          Parameters: []
        },
        DataService: {
          Name: "",
          Type: "DataService",
          SupportsRamWipe: true,
          SleepWipesRam: true,
          Parameters: []
        },
        SleepService: {
          Name: "AtsamSleepService with Backup",
          Type: "SleepService",
          SupportsRamWipe: true,
          SleepWipesRam: true,
          Parameters: []
        },
        TimerService: {
          Name: "ASTTimerService",
          Type: "TimerService",
          SupportsRamWipe: true,
          SleepWipesRam: true,
          Parameters: []
        },
      },
      editedUserpage: {
        Name: "",
        DevEUI: "00000000",
        AppEUI: "00000000",
        AppKey: "00000000",
        Fuses: {
          BOD1v8HystEnabled: false,
          BOD1v8Action: "None",
          BOD1v8Enabled: false,
          BOD1v8Level: 1.25,
          BOD3v3HystEnabled: false,
          BOD3v3Action: "None",
          BOD3v3Enabled: false,
          BOD3v3Level: 1.55,
          WDTAutoEnabled: false
        },
        CommunicationService: {
          Name: "",
          Type: "BasicCommunicationService",
          SupportsRamWipe: true,
          SleepWipesRam: true,
          Parameters: []
        },
        DataService: {
          Name: "",
          Type: "DataService",
          SupportsRamWipe: true,
          SleepWipesRam: true,
          Parameters: []
        },
        SleepService: {
          Name: "",
          Type: "SleepService",
          SupportsRamWipe: true,
          SleepWipesRam: true,
          Parameters: []
        },
        TimerService: {
          Name: "",
          Type: "TimerService",
          SupportsRamWipe: true,
          SleepWipesRam: true,
          Parameters: []
        },
      },
      json_fields: {
        'Nummer': 'number',
        'Type': 'type',
        'AppKey': 'appKey',
        'AppEUI': 'appEui',
        'DevEUI': 'devEui',
      },
      appeuidata: {
        searchValue: null,
        appEui: null,
        items: []
      },
      deveuidata: {
        items: []
      },
      do_chirpstack_generation: false,
      do_chirpstack_generation_single: false
    };
  },
  beforeCreate: function () {
    if (!this.$session.exists() || this.$session.get("authToken") == "") {
      // If there is no valid authentication session, reroute.
      this.$router.push("/");
    }
  },
  mounted: function () {
    // Get userpages on initial page load
    this.$nextTick(this.getUserpages());
    this.$nextTick(this.getAppEuis());
    this.$nextTick(this.getServices());
    this.$nextTick(this.getStatus());

    if (this.$session.get("role") != 1) {
      // Remove userid & actions columns if user isn't admin
      this.headers.splice(-2, 1);
    }
  },
  methods: {
    async fetchData() {
      const params = {
        token: this.$session.get("authToken")
      };

      // Post to retrieve devices, return data of said devices when done
      const res = await axios.post(this.$API_LINK + "/retrieve/userpages", params) // TODO: change this
      return res.data;
    },
    customFilter(value, search, item) {
      return value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().replaceAll(' ', '').toLowerCase().includes(search.toLowerCase())
    },
    refreshUserpages: function () {
      this.getUserpages()
      this.getAppEuis()
      this.getServices()
      this.getStatus()
    },
    getUserpages: function () {
      // Set loading userpages
      this.loadUserpages = true

      // Execute GET request to retrieve userpages for user matching authToken
      axios
        .get(this.$API_LINK + "/retrieve/userpages")
        .then(res => {
          if (res.status == 200) {
            // If the retrieval was successful
            this.loadUserpages = false

            // Set userpages variable to result data
            this.userpages = this.setValueDecodeds(res.data)
            this.selectedUserpages = []
          }
        })
        .catch(error => {
          if (error.status == 404) {
            // Handle 404
            console.log(error)
            this.loadUserpages = false
            this.notFoundAlert = true;
            // Automatically close alert after 10s
            setTimeout(() => {
              this.notFoundAlert = false
            }, 10000)
          }
        });
    },
    getStatus: function () {
      // Execute GET request to retrieve userpages for user matching authToken
      axios
        .get(this.$API_LINK + "/retrieve/status/all")
        .then(res => {
          if (res.status == 200) {

            // Set userpages variable to result data
            this.statusList = res.data;
            this.bulkStatusList = structuredClone(this.statusList)

            this.bulkStatusList.unshift({
              id: -1,
              name: "",
              description: "",
              color: "#ffffff"
            })
          }
        })
        .catch(error => {
          if (error.status == 404) {
            // Handle 404
            this.notFoundAlert = true;
            // Automatically close alert after 10s
            setTimeout(() => {
              this.notFoundAlert = false;
            }, 10000)
          }
        });
    },
    setValueDecodeds: function (userpages) {
      // Set local userpages instance
      let newUserpages = userpages
      let _this = this
      // Loop through userpages
      newUserpages.forEach(function (userpage, userpageIndex) {

        if (userpage.config.CommunicationService.Parameters != null) {
          // Loop through communicationservice parameters for current userpage
          userpage.config.CommunicationService.Parameters.forEach(function (parameter, parameterIndex) {
            if (parameter.Type == "BitField" && parameter.ArrayLength == 4 && parameter.Value !== null) {

              // Check whether we're dealing with SF values or not
              if (parameter.Name == "DefaultTransmissionDatarate") {
                // Create a new value called ValueDecoded, containing the decoded binary array
                newUserpages[userpageIndex].config.CommunicationService.Parameters[parameterIndex].ValueDecoded = _this.decodeSf(_this.decodeBitField(parameter.Value))
              } else {
                // Create a new value called ValueDecoded, containing the decoded binary array
                newUserpages[userpageIndex].config.CommunicationService.Parameters[parameterIndex].ValueDecoded = _this.decodeBitField(parameter.Value)
              }
            }
          })
        }
      })

      // Return updated userpages
      return newUserpages
    },
    getServices: function () {
      // Execute GET request to retrieve userpages for user matching authToken
      axios
        .get(this.$API_LINK + "/retrieve/services/0")
        .then(res => {
          if (res.status == 200) {
            // If the retrieval was successful
            // Set services variable to result data
            this.services = res.data;
          }
        })
        .catch(error => {
          if (error.status == 404) {
            // Handle 404
            this.notFoundAlert = true;
            // Automatically close alert after 10s
            setTimeout(() => {
              this.notFoundAlert = false
            }, 10000)
          }
        });
    },
    servicesByType: function (type) {
      let services = this.services
      let realType = 0

      switch (type) {
        case "BasicCommunicationService":
          realType = 1
          break
        case "IntervalCommunicationService":
          realType = 2
          break
        case "ContinuousCommunicationService":
          realType = 3
          break
        case "DataService":
          realType = 4
          break
        case "SleepService":
          realType = 5
          break
        case "TimerService":
          realType = 6
          break
        default:
          realType = 1
          break
      }

      return services.filter(s => s.type == realType)
    },
    decodeBitField: function (bitfield) {
      let bitStr = ""

      if (Array.isArray(bitfield)) {
        bitfield.forEach(function (x) {
          if (x) {
            bitStr = (bitStr + "1")
          } else {
            bitStr = (bitStr + "0")
          }
        })
      }

      return parseInt(bitStr, 2)
    },
    decodeSf: function (valNumber) {
      // Convert number to SF value
      let sfValue = "SF12"

      switch (valNumber) {
        case 0:
          sfValue = "SF12"
          break
        case 1:
          sfValue = "SF11"
          break
        case 2:
          sfValue = "SF10"
          break
        case 3:
          sfValue = "SF9"
          break
        case 4:
          sfValue = "SF8"
          break
        case 5:
          sfValue = "SF7"
          break
      }

      return sfValue
    },
    encodeSf: function (sfValue) {
      // Convert SF value to number
      let valNumber = 0

      switch (sfValue) {
        case "SF12":
          valNumber = 0
          break
        case "SF11":
          valNumber = 1
          break
        case "SF10":
          valNumber = 2
          break
        case "SF9":
          valNumber = 3
          break
        case "SF8":
          valNumber = 4
          break
        case "SF7":
          valNumber = 5
          break
      }

      return valNumber
    },
    paramsForService: function (serviceName) {
      let targetService = this.services.filter(s => s.name == serviceName)
      let _this = this

      if (targetService[0] !== undefined) {
        // Check for specific types
        targetService[0].content.Parameters.forEach(function (x, index) {

          if (x.Type == "BitField" && x.Value !== null && x.ArrayLength === 4) {
            if (x.Name == "DefaultTransmissionDatarate") {
              targetService[0].content.Parameters[index].ValueDecoded = _this.decodeSf(_this.decodeBitField(x.Value))
            } else {
              targetService[0].content.Parameters[index].ValueDecoded = _this.decodeBitField(x.Value)
            }

            targetService[0].content.Parameters[index].Value = x.Value
            targetService[0].content.Parameters[index].ArrayLength = x.Value.length
          } else {

            if (targetService[0].content.Parameters[index].Value == null) {
              targetService[0].content.Parameters[index].Value = x.Value
            }
          }
        })

        return targetService[0].content.Parameters
      }

      return null
    },
    changeServiceSelection: function (service) {
      // Get insertedUserpage(s) parameters
      switch (service) {
        case 'communicationsingle':
          this.insertedUserpage.CommunicationService.Parameters = this.paramsForService(this.insertedUserpage.CommunicationService.Name)
          this.editedUserpage.CommunicationService.Parameters = this.paramsForService(this.editedUserpage.CommunicationService.Name)
          break
        case 'datasingle':
          this.insertedUserpage.DataService.Parameters = this.paramsForService(this.insertedUserpage.DataService.Name)
          this.editedUserpage.DataService.Parameters = this.paramsForService(this.editedUserpage.DataService.Name)
          break
        case 'sleepsingle':
          this.insertedUserpage.SleepService.Parameters = this.paramsForService(this.insertedUserpage.SleepService.Name)
          this.editedUserpage.SleepService.Parameters = this.paramsForService(this.editedUserpage.SleepService.Name)
          break
        case 'timersingle':
          this.insertedUserpage.TimerService.Parameters = this.paramsForService(this.insertedUserpage.TimerService.Name)
          this.editedUserpage.TimerService.Parameters = this.paramsForService(this.editedUserpage.TimerService.Name)
          break
        case 'communicationmultiple':
          this.insertedUserpages.CommunicationService.Parameters = this.paramsForService(this.insertedUserpages.CommunicationService.Name)
          break
        case 'datamultiple':
          this.insertedUserpages.DataService.Parameters = this.paramsForService(this.insertedUserpages.DataService.Name)
          break
        case 'sleepmultiple':
          this.insertedUserpages.SleepService.Parameters = this.paramsForService(this.insertedUserpages.SleepService.Name)
          break
        case 'timermultiple':
          this.insertedUserpages.TimerService.Parameters = this.paramsForService(this.insertedUserpages.TimerService.Name)
          break
        case 'allsingle':
          this.insertedUserpage.CommunicationService.Parameters = this.paramsForService(this.insertedUserpage.CommunicationService.Name)
          this.insertedUserpage.DataService.Parameters = this.paramsForService(this.insertedUserpage.DataService.Name)
          this.insertedUserpage.SleepService.Parameters = this.paramsForService(this.insertedUserpage.SleepService.Name)
          this.insertedUserpage.TimerService.Parameters = this.paramsForService(this.insertedUserpage.TimerService.Name)

          this.editedUserpage.CommunicationService.Parameters = this.paramsForService(this.editedUserpage.CommunicationService.Name)
          this.editedUserpage.DataService.Parameters = this.paramsForService(this.editedUserpage.DataService.Name)
          this.editedUserpage.SleepService.Parameters = this.paramsForService(this.editedUserpage.SleepService.Name)
          this.editedUserpage.TimerService.Parameters = this.paramsForService(this.editedUserpage.TimerService.Name)
          break
        case 'allmultiple':
          this.insertedUserpages.TimerService.Parameters = this.paramsForService(this.insertedUserpages.TimerService.Name)
          this.insertedUserpages.SleepService.Parameters = this.paramsForService(this.insertedUserpages.SleepService.Name)
          this.insertedUserpages.DataService.Parameters = this.paramsForService(this.insertedUserpages.DataService.Name)
          this.insertedUserpages.CommunicationService.Parameters = this.paramsForService(this.insertedUserpages.CommunicationService.Name)
          break

      }
    },
    getAppEuis: function () {
      // Execute GET request to retrieve mac-adresses
      axios
        .get(this.$API_LINK + "/retrieve/mac/appeui")
        .then(res => {
          if (res.status == 200) {
            // If the retrieval was successful
            // Set macadresses variable to result data
            this.macadresses = res.data
          }
        })
        .catch(error => {
          if (error.status == 500) {
            this.unknownErrorAlert = true
            // Automatically close alert after 10s
            setTimeout(() => {
              this.unknownErrorAlert = false
            }, 10000)
          }
        })
    },
    insertUserpage: function () {
      this.getAppEuis()
      this.changeServiceSelection('allsingle')
      this.invalidFields = "";
      this.insertDialog = true;
    },
    insertUserpages: function () {
      this.getAppEuis()
      this.changeServiceSelection('allmultiple')
      this.csvDialog = true;
    },
    validateInsertUserpage: function () {
      if (this.$refs.insertSingleForm.validate()) {
        this.invalidFields = ""
        this.processCreateUserpage()
      } else {
        let error = this.$refs.insertSingleForm.inputs.filter(field => field.valid === false);
        this.invalidFields = "De volgende velden zijn incorrect: ";
        error.forEach(err => {
          this.invalidFields += err.$refs.label.innerHTML + ", ";
        })
        this.invalidFields = this.invalidFields.slice(0, this.invalidFields.length - 2)
      }
    },
    processCreateUserpage: function () {
      let _this = this

      // Foreach parameter in the communicationservice
      for (let i = 0; i < this.insertedUserpage.CommunicationService.Parameters.length; i++) {
        // Set current reference to communicationservice parameters
        let currentParam = this.insertedUserpage.CommunicationService.Parameters[i]
        // If currentParam is a bitfield value and not empty
        if (currentParam.Type == "BitField" && currentParam.ValueDecoded != null && currentParam.Name != "DefaultTransmissionDatarate") {

          // Copy bytes as string to new value arrVal
          let n = currentParam.ValueDecoded.toString(2).padStart(4, '0')
          let arrVal = Array.from(n).map(Number)

          // Update original array of parameters
          this.insertedUserpage.CommunicationService.Parameters[i].Value = arrVal
        } else if (currentParam.Type == "BitField" && currentParam.ValueDecoded != null && currentParam.Name == "DefaultTransmissionDatarate") {
          // Encode sf
          let realDecoded = _this.encodeSf(currentParam.ValueDecoded)

          // Copy bytes as string to new value arrVal
          let n = realDecoded.toString(2).padStart(4, '0')
          let arrVal = Array.from(n).map(Number)

          // Update original array of parameters
          this.insertedUserpage.CommunicationService.Parameters[i].Value = arrVal
        }

        // Set EUI's
        if (currentParam.Name == "DeviceEUI") {
          this.insertedUserpage.DevEUI = currentParam.Value
        } else if (currentParam.Name == "ApplicationEUI") {
          this.insertedUserpage.AppEUI = currentParam.Value
        } else if (currentParam.Name == "ApplicationKey") {
          if (currentParam.Value == "") {
            let tempAppKey = cryptoRandomString({ length: 32 })
            let correctAppKey = tempAppKey.match(/.{1,2}/g);
            this.insertedUserpage.AppKey = correctAppKey.join(' ')
          } else {
            this.insertedUserpage.AppKey = currentParam.Value.toLowerCase()
          }
        }
      }
      this.insertedUserpage.do_generate = this.do_chirpstack_generation_single
      // Perform POST request to insert userpage
      axios
        .post(this.$API_LINK + "/generate/userpage", this.insertedUserpage)
        .then(res => {
          if (res.status == 200) {
            // Destroy session and reroute to main page
            this.userpageInsertedAlert = true
            // Automatically close alert after 10s
            setTimeout(() => {
              this.userpageInsertedAlert = false
            }, 10000)

            this.insertDialog = false
            this.getUserpages()
          }
        })
        .catch(error => {
          if (error.response.status == 404) {
            // Handle 404
            this.userpageInsertFailedAlert = true
            this.userpageInsertFailedMessage = "Backend niet berijkbaar"
            // Automatically close alert after 10s
            setTimeout(() => {
              this.userpageInsertFailedAlert = false
            }, 10000)
          } else if (error.response.status == 400) {
            this.userpageInsertFailedAlert = true
            this.userpageInsertFailedMessage = error.response.data
          }
        });
    },
    validateInsertUserpages: function () {
      if (this.$refs.insertMultipleForm.validate()) {
        this.processCreateUserpages()
      }
    },
    processCreateUserpages: function () {
      // get all eui/keys...
      //Generate devEUIs and retrieve them.
      let realAppEui = this.macadresses.find(m => {
        return m.label === this.itemGroup
      }).address

      this.generateUserpages(realAppEui)
    },
    isToday: function (date) {
      const today = new Date();

      if (
        today.getFullYear() === date.getFullYear() &&
        today.getMonth() === date.getMonth() &&
        today.getDate() === date.getDate()
      ) {
        return true;
      }

      return false;
    },
    generateUserpages: function (appEui) {
      let _this = this
      let do_generate = this.do_chirpstack_generation
      let userpages = []

      let correctAppEUI = appEui.match(/.{1,2}/g);
      let appEuiLabel = this.itemGroup
      let groupName = Object.assign({}, _this.insertedUserpages).Name;

      axios
        .get(this.$API_LINK + `/retrieve/group/${groupName}`, this.insertedUserpage)
        .then(res => {
          if (res.status == 200) {

            //Use the amount of existings userpages with this name to offset the counter in the userpage name.
            let _userpages = this.setValueDecodeds(res.data)

            //Filter _userpages with todays date
            let groupLength = _userpages.length;

            for (let index = 0; index < _this.userpageAmount; ++index) {
              // For each deveui, appeui, appkey, create userpage
              let tempAppKey = cryptoRandomString({ length: 32 })
              let userpage = Object.assign({}, _this.insertedUserpages);

              let correctAppKey = tempAppKey.match(/.{1,2}/g);
              userpage.AppEUI = correctAppEUI.join(' ')
              userpage.AppKey = correctAppKey.join(' ')

              //Removed spaces from the name for consistency
              userpage.Name = userpage.Name.replaceAll(" ", "")

              //The group name will be the base of the userpage name.
              userpage.Group = userpage.Name

              //The userpage name is the groupname with a index appended, to keep the names unique.
              //If any userpage are made on the same day with the same name, the index is kept and futher incremented.
              userpage.Name = userpage.Name + "_" + (index + 1 + groupLength)

              // CommunicationService >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
              for (let i = 0; i < userpage.CommunicationService.Parameters.length; i++) {

                // Set current reference to communicationservice parameters
                let currentParam = userpage.CommunicationService.Parameters[i]

                // Set key values
                if (currentParam.Name == "ApplicationEUI") {
                  userpage.CommunicationService.Parameters[i].Value = correctAppEUI.join(' ')
                } else if (currentParam.Name == "ApplicationKey") {
                  userpage.CommunicationService.Parameters[i].Value = correctAppKey.join(' ')
                }
              }

              //The userpage needs to be deepcopied otherwise references to previous userpages will be pushed.
              userpages.push(structuredClone(userpage))
            }

            this.loadInsertBulk = true

            const requestBody = { userpages: userpages, label: groupName, app_eui: correctAppEUI.join(' '), app_eui_label: appEuiLabel, do_generate: do_generate }

            console.log(requestBody)

            // Perform POST request to insert userpage 
            axios
              .post(this.$API_LINK + "/generate/userpages", requestBody)
              .then(res => {
                if (res.status == 200) {
                  // Destroy session and reroute to main page
                  this.loadInsertBulk = false
                  this.csvDialog = false
                  this.getUserpages()
                  this.userpagesInsertedAlert = true
                  // Automatically close alert after 10s
                  setTimeout(() => {
                    this.userpagesInsertedAlert = false
                  }, 10000)
                }
              })
              .catch(error => {
                if (error.response.status == 404) {
                  // Handle 404
                  this.loadInsertBulk = false
                  this.userpagesInsertFailedAlert = true
                  // Automatically close alert after 10s
                  setTimeout(() => {
                    this.userpagesInsertFailedAlert = false
                  }, 10000)
                } else if (error.response.status = 400) {
                  this.loadInsertBulk = false
                  this.userpageInsertFailedAlert = true
                  this.userpageInsertFailedMessage = error.response.data
                } else {
                  this.loadInsertBulk = false
                  this.userpageInsertFailedAlert = true
                  this.userpageInsertFailedMessage = "Unknown error: " + error.response.status
                }
              });
          }
        })
        .catch(error => {
          // Handle 404
          console.log(error)
          this.loadInsertBulk = false
          this.csvDialog = false
          this.userpageInsertFailedAlert = true
          // Automatically close alert after 10s
          setTimeout(() => {
            this.userpageInsertFailedAlert = false
          }, 10000)
        });
    },
    editUserpage: function (userpage) {
      // Set selected userpage data to the editDialog form

      this.editedUserpage = userpage.item.config;
      this.editedUserpage.status_name = userpage.item.status_name;
      this.editedUserpage.status_id = userpage.item.status_id;
      this.editedUserpage.status_description = userpage.item.status_description;

      // let match = this.editedUserpage.Name.match(/(_{1}\d+)$/gm)
      // if (match != null) {
      //   this.editedUserpage.Name = this.editedUserpage.Name.replace(match[0], '')
      //   this.editedUserpage.bulkIndex = match[0].replace('_', '')
      // }
      this.editedId = userpage.item.id

      // Set editDialog to visible
      this.invalidFields = "";
      this.editDialog = true;
    },
    validateEditUserpage: function () {
      if (this.$refs.editSingleForm.validate()) {
        this.invalidFields = ""
        this.processEditUserpage()
      } else {
        let error = this.$refs.editSingleForm.inputs.filter(field => field.valid === false);
        this.invalidFields = "De volgende velden zijn incorrect: ";
        error.forEach(err => {
          this.invalidFields += err.$refs.label.innerHTML + ", ";
        })
        this.invalidFields = this.invalidFields.slice(0, this.invalidFields.length - 2)
      }
    },
    processEditUserpage: function () {
      let _this = this
      // if (this.editedUserpage.bulkIndex != null) {
      //   this.editedUserpage.Name = this.editedUserpage.Name + "_" + this.editedUserpage.bulkIndex
      // }

      // Foreach parameter in the communicationservice
      for (let i = 0; i < this.editedUserpage.CommunicationService.Parameters.length; i++) {

        // Set current reference to communicationservice parameters
        let currentParam = this.editedUserpage.CommunicationService.Parameters[i]

        // If currentParam is a bitfield value and not empty
        if (currentParam.Type == "BitField" && currentParam.ValueDecoded != null && currentParam.Name != "DefaultTransmissionDatarate") {

          // Copy bytes as string to new value arrVal
          let n = currentParam.ValueDecoded.toString(2).padStart(4, '0')
          let arrVal = Array.from(n).map(Number)

          // Update original array of parameters
          this.editedUserpage.CommunicationService.Parameters[i].Value = arrVal
        } else if (currentParam.Type == "BitField" && currentParam.ValueDecoded != null && currentParam.Name == "DefaultTransmissionDatarate") {
          // Encode sf
          let realDecoded = _this.encodeSf(currentParam.ValueDecoded)

          // Copy bytes as string to new value arrVal
          let n = realDecoded.toString(2).padStart(4, '0')
          let arrVal = Array.from(n).map(Number)

          // Update original array of parameters
          this.editedUserpage.CommunicationService.Parameters[i].Value = arrVal
        }

        // Set EUI's
        if (currentParam.Name == "DeviceEUI") {
          this.editedUserpage.DevEUI = currentParam.Value
        } else if (currentParam.Name == "ApplicationEUI") {
          this.editedUserpage.AppEUI = currentParam.Value
        } else if (currentParam.Name == "ApplicationKey") {
          this.editedUserpage.AppKey = currentParam.Value
        }
      }

      this.editDialog = false

      // Perform POST request to insert userpage
      axios
        .post(this.$API_LINK + "/update/userpage/" + this.editedId, this.editedUserpage)
        .then(res => {
          if (res.status == 200) {
            // Destroy session and reroute to main page
            this.userpageEditedAlert = true
            // Automatically close alert after 10s
            setTimeout(() => {
              this.userpageEditedAlert = false
            }, 10000)

            this.editDialog = false
            this.getUserpages()
          }
        })
        .catch(error => {
          if (error.response.status == 500) {
            // Handle 404
            this.userpageEditFailedAlert = true
            // Automatically close alert after 10s
            setTimeout(() => {
              this.userpageEditFailedAlert = false
            }, 10000)
          }
        });
    },
    editUserpages: function () {
      let dataserviceName = this.selectedUserpages[0].config.DataService.Name
      let dataserviceAdjectiveResult = true;
      let communicationserviceName = this.selectedUserpages[0].config.CommunicationService.Name
      let communicationserviceAdjectiveResult = true;
      for (let i = 0; i < this.selectedUserpages.length; i++) {
        if (dataserviceName != this.selectedUserpages[i].config.DataService.Name) {//Check whether the selections have the same data service name
          dataserviceAdjectiveResult = false
          break;
        }
        if (communicationserviceName != this.selectedUserpages[i].config.CommunicationService.Name) {//Check whether the selections have the same communication service name
          communicationserviceAdjectiveResult = false;
          break;
        }
      }

      this.dataservicesAdjective = dataserviceAdjectiveResult;
      this.communicationservicesAdjective = communicationserviceAdjectiveResult;

      if (this.dataservicesAdjective) {
        //More options if dataservices are the same
        this.editedUserpage = this.selectedUserpages[0].config
      }

      this.editBulkDialog = true;
      this.editBulkStatusId = -1

      let statusId = this.selectedUserpages[0].status_id

      //If the whole array consists of 1 status Id, change the select to this status id
      let filteredStatus = this.selectedUserpages.filter(up => { return up.status_id === statusId })
      if (filteredStatus.length === this.selectedUserpages.length) {
        this.editBulkStatusId = statusId
      }
    },
    resetBulkEdit: function () {
      this.editBulkName = ""
      this.editBulkStatusId = -1;
    },
    processEditUserpages: function () {

      let promises = [];
      let AppEuiString = ""
      let _this = this

      if (this.dataservicesAdjective) {
        let selectedService = this.services.find(s => s.name == this.editedUserpage.DataService.Name)
        let newAppEui = this.macadresses.find(m => m.label == selectedService.appeuilabel)

        if (typeof newAppEui != 'undefined') {
          for (let i = 0; i < 8; i++) {
            AppEuiString += newAppEui.address[i * 2] + newAppEui.address[i * 2 + 1] + " "
          }
        }
      }

      this.selectedUserpages.forEach((up, index) => {
        let currentUserpage = up.config;
        if (this.dataservicesAdjective) {
          if (this.enabledDataService) {
            currentUserpage.DataService = this.editedUserpage.DataService
            if (this.changeAppEUI && !this.enabledCommunicationService) {
              if (AppEuiString != "") {
                currentUserpage.AppEUI = AppEuiString
              }

              for (let i = 0; i < currentUserpage.CommunicationService.Parameters.length; i++) {
                // Set current reference to communicationservice parameters
                let currentParam = this.editedUserpage.CommunicationService.Parameters[i]

                // Set EUI's
                if (currentParam.Name == "ApplicationEUI") {
                  if (AppEuiString != "") {
                    // currentParam.Value = AppEuiString
                    currentUserpage.CommunicationService.Parameters[i].Value = AppEuiString
                  }
                }
              }
            }
          }
        }
        if (this.enabledCommunicationService) {
          for (let i = 0; i < currentUserpage.CommunicationService.Parameters.length; i++) {
            // Set current reference to communicationservice parameters
            let currentParam = this.editedUserpage.CommunicationService.Parameters[i]

            // If currentParam is a bitfield value and not empty
            if (currentParam.Type == "BitField" && currentParam.ValueDecoded != null && currentParam.Name != "DefaultTransmissionDatarate") {
              // Copy bytes as string to new value arrVal
              let n = currentParam.ValueDecoded.toString(2).padStart(4, '0')
              let arrVal = Array.from(n).map(Number)

              // Update original array of parameters
              currentUserpage.CommunicationService.Parameters[i].Value = arrVal
            } else if (currentParam.Type == "BitField" && currentParam.ValueDecoded != null && currentParam.Name == "DefaultTransmissionDatarate") {
              // Encode sf
              let realDecoded = _this.encodeSf(currentParam.ValueDecoded)

              // Copy bytes as string to new value arrVal
              let n = realDecoded.toString(2).padStart(4, '0')
              let arrVal = Array.from(n).map(Number)

              // Update original array of parameters
              currentUserpage.CommunicationService.Parameters[i].Value = arrVal
            }
            if (currentParam.Name == "ApplicationEUI" && this.changeAppEUI) {
              currentUserpage.CommunicationService.Parameters[i].Value = currentParam.Value
              currentUserpage.AppEUI = currentParam.Value
            }
          }
        }

        if (this.editBulkName !== "") {
          up.name = this.editBulkName + "_" + (index + 1);
          up.config.Name = this.editBulkName + "_" + (index + 1);
          currentUserpage.Group = this.editBulkName;
        }

        if (this.editBulkStatusId != -1) {
          up.status_id = this.editBulkStatusId;
        }

        currentUserpage.status_name = up.status_name;
        currentUserpage.status_id = up.status_id;
        currentUserpage.status_description = up.status_description;

        this.editedId = up.id;
        promises.push(axios.post(this.$API_LINK + "/update/userpage/" + this.editedId, currentUserpage));

      });

      Promise.all(promises)
        .then(results => {
          if (results.filter(res => { return res.status != 200 }).length === 0) {
            // Destroy session and reroute to main page
            this.userpageEditedAlert = true
            this.editBulkDialog = false;
            // Automatically close alert after 10s
            setTimeout(() => {
              this.userpageEditedAlert = false
            }, 10000)

            this.getUserpages()
            this.editBulkDialog = false;
          }
        })
        .catch(error => {
          console.log(error)
          this.userpageEditFailedAlert = true
          this.editBulkDialog = false;
          this.dataservicesAdjective = false;

          // Automatically close alert after 10s
          setTimeout(() => {
            this.userpageEditFailedAlert = false
          }, 10000)
        }
        );

      this.resetBulkEdit()
      this.editBulkDialog = false;
      this.dataservicesAdjective = false;
      this.communicationservicesAdjective = false;
      this.enabledDataService = false;
      this.enabledCommunicationService = false;
      this.changeAppEUI = false;
    },
    deleteUserpages: function () {
      if (confirm("Weet u zeker dat u " + this.selectedUserpages.length + " userpages wilt verwijderen?")) {
        let promises = [];

        this.selectedUserpages.forEach((up, index) => {

          const params = {
            id: up.id
          };
          promises.push(axios
            .post(this.$API_LINK + "/delete/userpage", params)
          );

        });

        Promise.all(promises)
          .then(results => {
            if (results.filter(res => { return res.status != 200 }).length === 0) {
              this.getUserpages()
            }
          })
          .catch(error => {
            console.log(error)
          }
          );
      }
    },
    deleteUserpage: function (userpage) {
      if (confirm("Weet u zeker dat u deze userpage wilt verwijderen?")) {
        const params = {
          id: userpage.item.id
        };

        // Perform DELETE request to delete user
        axios
          .post(this.$API_LINK + "/delete/userpage", params)
          .then(res => {
            if (res.status == 200) {
              // Remove item from table
              this.getUserpages()
            }
          })
          .catch(error => {
            if (error.status == 500) {
              // Handle 500
              this.unknownErrorAlert = true;
              // Automatically close alert after 10s
              setTimeout(() => {
                this.unknownErrorAlert = false
              }, 10000)
            }
          });
      }
    },
    searchMac: function (value) {
      // Search for AppEUI
      // To do: validation!!!!!!!!!!!!!!!!!!
      axios
        .get(this.$API_LINK + "/retrieve/keys/appeui/" + value)
        .then(res => {
          if (res.status == 200) {
            // Remove item from table
            this.appeuidata.items = res.data
          }
        })
        .catch(error => {
          if (error.status == 500) {
            // Handle 500
            this.unknownErrorAlert = true;
            // Automatically close alert after 10s
            setTimeout(() => {
              this.unknownErrorAlert = false
            }, 10000)
          }
        });
    },
    fetchDevEuis: function () {
      // Search for AppEUI
      // To do: validation!!!!!!!!!!!!!!!!!!
      let realAppEui = this.macadresses.find(m => {
        return m.label === this.itemGroup
      }).address
      axios
        .get(this.$API_LINK + "/retrieve/keys/deveui/" + realAppEui)
        .then(res => {
          if (res.status == 200) {
            // Handle result
            this.deveuidata.items = res.data
            return res.data
            // this.groupdata.items = res.data
          }
        })
        .catch(error => {
          if (error.status == 500) {
            // Handle 500
            this.unknownErrorAlert = true;
            // Automatically close alert after 10s
            setTimeout(() => {
              this.unknownErrorAlert = false
            }, 10000)
          }
        });
    },
    processGenerateBulk: function () {
      // Validate form data
      if (this.userpageAmount != 0) {

        // Initialize constants
        let realAppLabel = this.itemGroup
        let realAppEui = this.macadresses.find(m => {
          return m.label === this.itemGroup
        }).address

        const realDevLabel = this.insertedUserpages.Name
        const realDevCount = this.userpageAmount

        const realItem = {
          appEui: realAppEui,
          appEuiLabel: realAppLabel,
          devEuiCount: parseInt(realDevCount),
          devEuiLabel: realDevLabel
        }

        // POST request
        return axios.post(this.$API_LINK + "/generate/mac/bulk", realItem)
      }
    },
    toStepper2: function () {
      let service = this.services.find(s => s.appeuilabel == this.itemGroup)
      if (service != null) {
        this.insertedUserpages.DataService.Name = service.name
        this.changeServiceSelection('allmultiple')
      }
      this.bulkStepper = 2
    },
    printUserpages: function () {
      if (this.selectedUserpages.length > 0) {
        var element = document.createElement('a');
        let result = "dev_eui;app_eui;app_key;\n"

        this.selectedUserpages.forEach(userpage => {
          result += userpage.devEui.replace(/\s/g, "").toUpperCase() + ";"
          result += userpage.appEui.replace(/\s/g, "").toUpperCase() + ";"
          result += userpage.appKey.replace(/\s/g, "").toUpperCase() + ";\n"
        })

        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(result));
        element.setAttribute('download', "exported.csv");
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
      }
    },
    downloadUserpage: function (userpage) {
      axios
        .get(this.$API_LINK + "/retrieve/userpage/" + userpage.item.name + "/hex")
        .then(res => {
          if (res.status == 200) {
            // Handle result
            const up = res.data.find(up => { return up.name == userpage.item.name })

            if (up != null) {
              const hexToBytes = hex => { for (var bytes = [], c = 0; c < hex.length; c += 2) bytes.push(parseInt(hex.substr(c, 2), 16)); return bytes; };
              const res = (hexToBytes(up.hexstring));

              let blob = new Blob([new Uint8Array(res)], { type: 'application/octet-stream' })

              var element = document.createElement('a');

              element.setAttribute('href', URL.createObjectURL(blob));
              element.setAttribute('download', up.name + ".bin");

              element.style.display = 'none';
              document.body.appendChild(element);
              element.click();
              document.body.removeChild(element);
            }
          }
        })
        .catch(error => {
          if (error.status == 500) {
            // Handle 500
            this.unknownErrorAlert = true;
            // Automatically close alert after 10s
            setTimeout(() => {
              this.unknownErrorAlert = false
            }, 10000)
          }
        });
    },
    downloadUserpages: function () {
      if (this.selectedUserpages.length > 0) {
        var zip = new JSZip()
        let promises = [];

        this.selectedUserpages.forEach(userpage => {
          promises.push(axios
            .get(this.$API_LINK + "/retrieve/userpage/" + userpage.name + "/hex")
            .then(res => {
              if (res.status == 200) {
                // Handle result
                const up = res.data.find(up => { return up.name == userpage.name })

                if (up != null) {
                  const hexToBytes = hex => { for (var bytes = [], c = 0; c < hex.length; c += 2) bytes.push(parseInt(hex.substr(c, 2), 16)); return bytes; };
                  const res = (hexToBytes(up.hexstring));

                  zip.file(up.name + ".bin", res)
                }
              }
            })
            .catch(error => {
              if (error.status == 500) {
                // Handle 500
                this.unknownErrorAlert = true;
                // Automatically close alert after 10s
                setTimeout(() => {
                  this.unknownErrorAlert = false
                }, 10000)
              }
            }));


        })

        Promise.all(promises)
          .then(results => {
            zip.generateAsync({ type: "blob" })
              .then(function (blob) {
                var element = document.createElement('a');

                element.setAttribute('href', URL.createObjectURL(blob));
                element.setAttribute('download', "binaries.zip");

                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
              });
          })


      }
    },
    addDevicesChirpstack: function () {
      var devices = []
      if (this.selectedUserpages.length > 0) {
        this.selectedUserpages.forEach(userpage => {
          console.log(userpage)
          devices.push({
            dev_eui: userpage.devEui.split(' ').join(''),
            join_eui: userpage.appEui.split(' ').join(''),
            app_key: userpage.appKey.split(' ').join('')
          })
        })
      }

      axios.post(this.$API_LINK + "/generate/chirpstack", devices)
        .then(res => {
          if (res.status == 200) {
            this.genericSucces = true;
            this.genericSuccesMessage = "Succesvol toegevoegd aan chirpstack"

            setTimeout(() => {
              this.genericSucces = false
            }, 10000)
          }
        })
        .catch(err => {
          if (err.response.status == 400) {
            this.genericAlert = true
            this.genericAlertMessage = err.response.data

            // Automatically close alert after 10s
            setTimeout(() => {
              this.genericAlert = false
            }, 10000)
          }
        })
    },
    printSticker: function () {
      let stickerCodes = this.selectedUserpages.flatMap((up) => {
        let service = this.services.find(s => s.name == up.config.DataService.Name)
        if (service != null) {
          return [service.sticker_code, service.sticker_code];
        }
        return ["", ""];
      })
      let data = {
        selectedUserpages: this.selectedUserpages.flatMap(up => [up.id, up.id]),
        stickerCode: stickerCodes,
        deveui: this.selectedUserpages.flatMap(up => [up.devEui.replaceAll(" ", "").toUpperCase(), up.devEui.replaceAll(" ", "").toUpperCase()]),
        firmwareVersion: this.printedVersion,
        index: Math.min(Math.max(this.printedIndex - 1, 0), 40)
      };

      axios.post(this.$API_LINK + "/print/sticker", data, { responseType: 'blob' })
        .then(response => {
          // Creëer een nieuwe Blob van de PDF data
          const blob = new Blob([response.data], { type: 'application/pdf' });

          // Creëer een URL voor de Blob
          const pdfUrl = window.URL.createObjectURL(blob);

          const newWindow = window.open(pdfUrl);
          // Controleer of het nieuwe venster succesvol is geopend
          if (newWindow) {
            // Wacht tot het nieuwe venster geladen is voordat je de print dialoog opent
            newWindow.onload = function () {
              try {
                newWindow.focus();
                newWindow.print();
              } catch (e) {
                console.error("Er is een fout opgetreden bij het openen van de print dialoog: ", e);
              }
            };
          } else {
            console.error('Het nieuwe venster kon niet worden geopend');
          }
        })
        .catch(error => {
          console.error("Er is een fout opgetreden: ", error);
        });
      this.printedVersion = "V1.00"
      this.printDialog = false
      this.printedIndex = 1
    },
  }
};
</script>

<style>
.paramtextfield v-text-field {
  padding: 0 !important;
}

.paramtextfield input {
  padding: 0 !important;
}

.paramcol {
  padding: 0px 16px;
}
</style>
]